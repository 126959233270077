import React from 'react';
import Button from 'components/common/Button';
import Modal, { useModal } from 'components/common/Modal';
import PricingPlans from 'components/subscribe/PricingPlans';
import useUserSubscription from 'hooks/useUserSubscription';
import { ReactComponent as ArrowUpIco } from 'assets/icons/navbar/arrow.svg';
import { ReactComponent as LightningIco } from 'assets/icons/navbar/lightning.svg';
import { ReactComponent as RequestIco } from 'assets/icons/navbar/request.svg';
import { ReactComponent as SuitcaseIco } from 'assets/icons/navbar/suitcase.svg';
import Separator from 'components/common/Separator';
import { useUpgradePlanHandler } from 'hooks/useSubscribe';
import NavbarItem from './NavbarItem';
import styles from './AppNavbar.module.scss';
import UserDropdown from './UserDropdown/UserDropdown';

const AppNavbar = () => {
  const subscription = useUserSubscription();
  const handleUpgrade = useUpgradePlanHandler();
  const { showModal, hideModal, isVisible } = useModal();
  const { plan, product, period, businessesLeft, requestsLeft } = subscription;
  const modalStyle = {
    content: { width: '70%', height: '97%' },
  };

  return (
    <nav className={styles.mainNav}>
      <ul className={styles.mainNavItems}>
        <NavbarItem className={styles.NavItemBtn}>
          <Button color="secondary" onClick={showModal}>
            Upgrade <ArrowUpIco />
          </Button>
        </NavbarItem>
        <NavbarItem className={styles.NavItem} iconComponent={<LightningIco />} value={plan} />
        <Separator />
        <NavbarItem
          className={styles.NavItem}
          iconComponent={<SuitcaseIco />}
          value={businessesLeft}
          title="businesses"
        />
        <Separator />
        <NavbarItem className={styles.NavItem} iconComponent={<RequestIco />} value={requestsLeft} title="requests" />
      </ul>
      <UserDropdown subscription={subscription} />
      <Modal close={hideModal} visible={isVisible} modalStyle={modalStyle}>
        <PricingPlans upgradePlan={handleUpgrade} product={product} period={period} />
      </Modal>
    </nav>
  );
};

export default AppNavbar;
