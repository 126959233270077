import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import Colors from 'utils/chartColors';

const DonutChart = ({ data }) => {
  return (
    <PieChart width={180} height={130}>
      <Pie
        data={data}
        cx={60}
        cy={60}
        innerRadius={40}
        outerRadius={60}
        fill="#8884d8"
        paddingAngle={5}
        dataKey="value"
      >
        {data.map((item, index) => (
          <Cell key={item.label} fill={Colors[index % Colors.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default DonutChart;
