import React, { useMemo } from 'react';
import Slider from 'rc-slider';
import useUserSubscription from 'hooks/useUserSubscription';
import Modal, { useModal } from 'components/common/Modal';
import SpecialBusinessSearchRequest from '../SpecialBusinessSearchRequest';
import 'rc-slider/assets/index.css';
import styles from './NewListCalculations.module.scss';

const DEFAULT_SLIDER_VALUE = 10;

const generateSliderLabels = (step, max) => {
  const steps = Math.floor(max / step);
  const result = {};
  for (let i = 1; i <= steps; i += 1) {
    result[i * step] = i * step;
  }
  return result;
};

const NewListCalculations = (props) => {
  const { showModal, hideModal, isVisible } = useModal();
  const { count, sliderCount, setSliderCount } = props;
  const { businessesLeft, requestsLeft } = useUserSubscription();

  const marks = useMemo(() => generateSliderLabels(DEFAULT_SLIDER_VALUE, 30), []);

  return (
    <div className={styles.container}>
      <span className={styles.text}>You have</span>
      <div className={styles.squareInfoGrey}>
        <div className={styles.squareInfoItem}>
          <div className={styles.amount}>{businessesLeft}</div>
          <div className={styles.kind}>Businesses</div>
        </div>
        <hr className={styles.divider} />
        <div className={styles.squareInfoItem}>
          <div className={styles.amount}>{requestsLeft}</div>
          <div className={styles.kind}>Requests</div>
        </div>
      </div>

      <span className={styles.text}>We have information of</span>
      <div className={styles.countBusinesses}>
        <b>{count}</b> Businesses
      </div>
      <Slider
        defaultValue={DEFAULT_SLIDER_VALUE}
        min={10}
        max={30}
        step={10}
        onAfterChange={setSliderCount}
        dots
        marks={marks}
        trackStyle={{ backgroundColor: '#5C25D3', height: '8px', borderRadius: '10px' }}
        handleStyle={{
          background: '#FFF',
          padding: '4px',
          border: '6px solid #5C25D3',
        }}
        railStyle={{ backgroundColor: '#F4F6FA' }}
        dotStyle={{ display: 'none' }}
        style={{ width: '410px' }}
      />
      <br />
      <div>
        <span className={styles.text}>You will stay with</span>
        <br />
        <div className={styles.squareInfoRed}>
          <div className={styles.squareInfoItem}>
            <div className={styles.amount}>{businessesLeft - sliderCount}</div>
            <div className={styles.kind}>Businesses</div>
          </div>
          <hr className={styles.divider} />
          <div className={styles.squareInfoItem}>
            <div className={styles.amount}>{requestsLeft - 1}</div>
            <div className={styles.kind}>Requests</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewListCalculations;
