import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { CURRENT_USER_FRAGMENT } from 'hooks/useUser';
import { toast } from 'react-toastify';

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfile($profile: UpdateUserProfile!) {
    updateUserProfile(profile: $profile) {
      ...currentUser
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

const useUpdateProfile = () => {
  const [updateProfileMutation] = useMutation(UPDATE_USER_PROFILE, {
    onCompleted: () => toast.success('Profile updated'),
  });

  const updateProfile = useCallback(
    async (variables) => {
      try {
        await updateProfileMutation({
          variables: { profile: variables },
        });
        return;
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error;
        throw e;
      }
    },
    [updateProfileMutation],
  );

  return updateProfile;
};

export default useUpdateProfile;
