import { useMemo } from 'react';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { useQuery } from 'react-apollo';

const GET_CITIES = gql`
  query cities($country: String!) {
    cities(country: $country)
  }
`;

const useSearchCities = (country) => {
  const { data, loading } = useQuery(GET_CITIES, { variables: { country }, skip: !country });
  const cities = useMemo(() => get(data, 'cities', []).map((city) => ({ value: city, label: city })), [data]);
  return [cities, loading];
};

export default useSearchCities;
