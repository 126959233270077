import gql from 'graphql-tag';
import { CURRENT_USER_FRAGMENT } from 'hooks/useUser';
import useAuth from './useAuth';

export const LOGIN_MUTATION = gql`
  mutation loginWithPassword($email: String!, $password: String!, $remember: Boolean!) {
    loginWithPassword(email: $email, password: $password, remember: $remember) {
      user {
        ...currentUser
      }
      token
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

const useLogin = () => {
  const [login] = useAuth(LOGIN_MUTATION);
  return login;
};

export default useLogin;
