const LS_TOKEN_KEY = '__BOOST_ACCESS_TOKEN__';
class TokenManager {
  token;

  constructor() {
    this.token = localStorage.getItem(LS_TOKEN_KEY);
  }

  setToken(token) {
    this.token = token;
    return this.persist();
  }

  getToken() {
    return this.token;
  }

  clearToken() {
    this.token = '';
    return this.persist();
  }

  persist() {
    return localStorage.setItem(LS_TOKEN_KEY, this.token);
  }
}

export default new TokenManager();
