import React from 'react';
import * as yup from 'yup';
import { Formik, Field, Form } from 'formik';
import FormFeedback from 'components/form/FormFeedback';
import TextArea from 'components/form/formik/TextArea';
import TabTitle from 'components/common/TabTitle';
import Button from '../Button';
import styles from './SupportForm.module.scss';

const initialValue = {
  message: '',
};

const supportShape = {
  message: yup.string().required('Message is required.'),
};

const supportParamsSchema = yup.object().shape(supportShape);

const SupportForm = ({ onSubmit }) => {
  return (
    <Formik initialValues={initialValue} validationSchema={supportParamsSchema} onSubmit={onSubmit}>
      {({ isSubmitting, errors }) => (
        <>
          <TabTitle>Support</TabTitle>
          <div className={styles.container}>
            <div>
              <Form>
                <Field
                  className={styles.messageField}
                  name="message"
                  component={TextArea}
                  minRows={15}
                  label="Describe what it is all about?"
                />
                <FormFeedback valid={false} style={{ display: 'block' }}>
                  {errors.form}
                </FormFeedback>
                <div className={styles.buttonContainer}>
                  <Button disabled={isSubmitting} loading={isSubmitting} type="submit">
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
            <img src={require('assets/img/support.svg')} alt="Support" />
          </div>
        </>
      )}
    </Formik>
  );
};

export default SupportForm;
