/* eslint-disable no-console */
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import settings from 'settings';
import TokenManager from './token-manager';

const link = ApolloLink.from([
  setContext((_, { headers }) => {
    const token = TokenManager.getToken();
    if (token) {
      const authHeaders = { Authorization: `Bearer ${token}` };
      return { headers: { ...headers, ...authHeaders } };
    }
    return { headers };
  }),
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
      );
    if (networkError) console.log(`[Network error]: ${networkError}`);
  }),
  new HttpLink({
    uri: settings.graphqlServerUrl,
    credentials: 'same-origin',
  }),
]);

const cacheIndexBlacklist = [];

const client = new ApolloClient({
  // request: operation => {
  //   const token = TokenManager.getToken();
  //   console.log(token);
  //   if (token) {
  //     const headers = { Authorization: `Bearer ${token}` };
  //     operation.setContext({ headers });
  //   }
  // },
  link,
  cache: new InMemoryCache({
    freezeResults: true, // new
    dataIdFromObject: ({ _id, __typename }) => {
      if (cacheIndexBlacklist.includes(__typename)) return null;
      return _id ? `${__typename}___${_id}` : null;
    },
  }),
  assumeImmutableResults: true, // new
});

export default client;
