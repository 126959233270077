/* global gapi */
/* cSpell: disable */
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import useAuth from 'hooks/auth/useAuth';
import routePaths from 'router/route-paths';
import useUser, { CURRENT_USER_FRAGMENT } from 'hooks/useUser';
import { toast } from 'react-toastify';

const AUTH_WITH_GOOGLE = gql`
  mutation authWithGoogle($token: String!) {
    authWithGoogle(token: $token) {
      token
      user {
        ...currentUser
      }
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

const useGoogleAuthButton = () => {
  const [me] = useUser();
  const mutationParams = me ? { refetchQueries: ['me'] } : {};
  const [auth, loading] = useAuth(AUTH_WITH_GOOGLE, mutationParams);
  const history = useHistory();

  const onSuccess = useCallback(
    async (user) => {
      const response = user.getAuthResponse();
      try {
        await auth({ token: response.id_token });
        if (me) toast.success('Service was successfully connected');
        else history.push(routePaths.home);
      } catch (e) {
        toast.error(e.message);
      }
    },
    [auth, history, me],
  );

  // TODO: Toast
  const onError = useCallback((error) => console.log('error => ', error), []);

  const login = useCallback(
    () =>
      new Promise((resolve, reject) => {
        gapi.load('auth2', async () => {
          const auth2 = gapi.auth2.init({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            cookiepolicy: 'single_host_origin',
            // Request scopes in addition to 'profile' and 'email'
            scope: 'openid',
          });
          try {
            const user = await auth2.signIn();
            onSuccess(user);
            resolve(user);
          } catch (error) {
            onError(error);
            reject(error);
          }
        });
      }),
    [onError, onSuccess],
  );

  return [login, loading];
};

export default useGoogleAuthButton;
