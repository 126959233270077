export default {
  free: {
    businesses: 30,
    requests: 2,
    campaigns: 1,
  },
  starter: {
    businesses: 150,
    requests: 5,
    campaigns: 3,
  },
  pro: {
    businesses: 1000,
    requests: 30,
    campaigns: 1000,
  },
  enterprise: {
    businesses: 3000,
    requests: 100,
    campaigns: 1000,
  },
};
