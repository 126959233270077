import React, { useCallback } from 'react';
import * as yup from 'yup';
import { Formik, Field, Form } from 'formik';
import TextInput from 'components/form/formik/TextInput';
import TextArea from 'components/form/formik/TextArea';
import { useAlert } from 'components/common/Alert';
import { ReactComponent as SendIcon } from '../../assets/icons/telegram.svg';
import styles from './SpecialBusinessSearchRequest.module.scss';
import Button from '../common/Button/Button';
import useBusinessRequest from '../../hooks/useBusinessRequest';

const useContactSupportHandler = () => {
  const { showAlert } = useAlert();

  const businessRequest = useBusinessRequest();

  const handleSubmit = useCallback(
    async (variables, { resetForm }) => {
      try {
        await businessRequest(variables);
        resetForm({});
        showAlert({ title: 'Your request has been sent successfully', image: require('assets/img/subscribe.svg') });
      } catch (error) {
        showAlert({ title: error.message });
      }
    },
    [businessRequest, showAlert],
  );

  return [handleSubmit];
};

const initialValue = {
  country: '',
  city: '',
  category: '',
  description: '',
};

const supportShape = {
  country: yup.string().required('Country is required.'),
  city: yup.string().required('City is required.'),
  category: yup.string().required('Category is required.'),
  description: yup.string().required('Description is required.'),
};

const supportParamsSchema = yup.object().shape(supportShape);

const SpecialBusinessSearchRequest = () => {
  const [handleSubmit] = useContactSupportHandler();
  return (
    <Formik initialValues={initialValue} validationSchema={supportParamsSchema} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <>
          <div className={styles.infoContainer}>
            <div className={styles.titlePage}>Special requests</div>
            <div className={styles.infoText}>
              please choose a country, city, and category and we will get back
              <br />
              to you with the results
            </div>
          </div>
          <div className={styles.inputSection}>
            <div className={styles.title}>Your information</div>
            <div>
              <div>
                <Form>
                  <div className={styles.mainDataSection}>
                    <Field name="country" component={TextInput} label="Country" className={styles.input} />
                    <Field name="city" component={TextInput} label="City/State" className={styles.input} />
                    <Field name="category" component={TextInput} label="Category" />
                  </div>
                  <div className={styles.submitSection}>
                    <Field
                      name="description"
                      component={TextArea}
                      label="Description"
                      className={styles.textArea}
                      minRow={5}
                    />
                    <div className={styles.buttonContainer}>
                      <Button disable={isSubmitting} loading={isSubmitting} type="submit">
                        Send <SendIcon />
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

export default SpecialBusinessSearchRequest;
