import React, { useRef, useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as WordIcon } from 'assets/icons/word.svg';
import { ReactComponent as PdfIcon } from 'assets/icons/pdf.svg';
import Dropzone from 'components/common/Dropzone';
import useUploadFile from 'hooks/useUploadFile';
import Loading from 'components/common/Loading';
import styles from './FilesField.module.scss';

const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
};

const useUploadFileHandler = (file) => {
  const uploadFile = useUploadFile(file);

  const handleUpload = useCallback(async () => {
    try {
      return uploadFile();
    } catch (error) {
      const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
      e.raw = error;
      throw e;
    }
  }, [uploadFile]);
  return handleUpload;
};

const FilesField = ({ form }) => {
  const fileRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const uploadFile = useUploadFileHandler(fileRef);

  const handleUploadFile = useCallback(async () => {
    try {
      const url = await uploadFile();
      const { name, size } = fileRef.current;
      const fileSize = bytesToSize(size);
      form.setFieldValue('attachments', [{ url, name, size: fileSize }, ...form.values.attachments]);
      setUploading(false);
    } catch (error) {
      const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
      e.raw = error;
      throw e;
    }
  }, [form, uploadFile]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setUploading(true);
      const attachedFile = await acceptedFiles.pop();
      fileRef.current = attachedFile;
      handleUploadFile();
    },
    [handleUploadFile],
  );

  const getFileIcon = (fileName) => {
    const positionPoint = fileName.lastIndexOf('.');
    const fileType = fileName.slice(positionPoint + 1);
    switch (fileType) {
      case 'docx':
        return <WordIcon />;
      case 'pdf':
        return <PdfIcon />;
      default:
        break;
    }
  };

  const handleDeleteAttachment = (urlAttachment) => {
    const newAttachments = form.values.attachments.filter(({ url }) => urlAttachment !== url);
    form.setFieldValue('attachments', [...newAttachments]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const dropzone = { getInputProps, getRootProps };

  return (
    <div className={styles.uploadFileContainer}>
      <Dropzone dropzone={dropzone} className={styles.dropzone} />
      <div>{uploading && <Loading size={16} />}</div>
      {form.values.attachments.map(({ name, size, url }) => (
        <div key={url} className={styles.fileContainer}>
          <a className={styles.wrapper} href={url} download={name}>
            <div>{getFileIcon(name)}</div>
            <div className={styles.fileInfoContainer}>
              <div className={styles.fileName}>{name}</div>
              <div className={styles.fileSize}>{size}</div>
            </div>
          </a>
          <button type="button" className={styles.deleteBtn} onClick={() => handleDeleteAttachment(url)}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      ))}
    </div>
  );
};

export default FilesField;
