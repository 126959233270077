import { useMemo } from 'react';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';

export const LIST_FRAGMENT = gql`
  fragment list on List {
    _id
    createdAt
    category
    location {
      city
      country
    }
    businessesCount
    # businessUnits {
    #   _id
    #   name
    #   location {
    #     city
    #     address
    #   }
    #   urls {
    #     webSiteUrl
    #   }
    #   rating
    #   reviewCount
    # }
    isArchived
  }
`;

export const GET_MY_LIST_QUERY = gql`
  query lists($offset: Int!, $limit: Int!) {
    lists(offset: $offset, limit: $limit) @connection(key: "lists") {
      ...list
    }
  }
  ${LIST_FRAGMENT}
`;

const useMyLists = () => {
  const { data, loading, fetchMore } = useQuery(GET_MY_LIST_QUERY, {
    variables: {
      limit: 5,
      offset: 0,
    },
  });

  const lists = useMemo(() => get(data, 'lists', []), [data]);

  return {
    lists,
    loading,
    fetchMore,
  };
};

export default useMyLists;
