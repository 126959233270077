import React from 'react';
import * as yup from 'yup';
import { Formik, Field, Form } from 'formik';
import FormSelect from 'components/form/formik/Select';
import FormFeedback from 'components/form/FormFeedback';
import Button from 'components/common/Button';
import TextArea from 'components/form/formik/TextArea';
import styles from './ReportForm.module.scss';
import reasons from './reasons';

const initialValues = {
  reason: '',
  message: '',
};

const reportShape = {
  reason: yup.string().required('Reason is required'),
  message: yup.string().required('Message is required'),
};

const schema = yup.object().shape(reportShape);

const ReportForm = ({ onSubmit, onCancel, row, response }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Report</div>
      <div className={styles.message}>Share with us your experience or information regarding the data provided.</div>
      <div>{row}</div>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
        {({ isSubmitting, errors }) => (
          <>
            <div>
              <Form>
                <div className={styles.selectContainer}>
                  <Field
                    styles={styles.selectTypeReport}
                    name="reason"
                    component={FormSelect}
                    options={reasons}
                    placeholder="Choose reason of report"
                  />
                </div>
                <Field
                  customStyles={styles.description}
                  name="message"
                  component={TextArea}
                  minRows={15}
                  placeholder="Description"
                />
                <FormFeedback valid={false} style={{ display: 'block' }}>
                  {errors.form}
                </FormFeedback>
                <div>
                  <Button
                    className={styles.button}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    color="primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                  <Button
                    className={styles.button}
                    disabled={isSubmitting}
                    onClick={onCancel}
                    color="cancel"
                    type="button"
                  >
                    Cancel
                  </Button>
                </div>
                {response && (
                  <div className={response.isError ? styles.wrongResponse : styles.successResponse}>
                    {response.message}
                  </div>
                )}
              </Form>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default ReportForm;
