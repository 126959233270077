import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'components/common/Button';
import routePaths from 'router/route-paths';
import styles from './SecondaryContent.module.scss';

export default ({ isLogin }) => (
  <div>
    {isLogin ? (
      <div>
        <img className={styles.image} alt="..." src={require('assets/img/auth/login.svg')} />
        <div>Not registered?</div>
        <Button className={styles.button} color="primary" as={Link} to={routePaths.signUp}>
          <p>Sign up</p>
        </Button>
      </div>
    ) : (
      <div>
        <img className={styles.image} alt="..." src={require('assets/img/auth/sign-up.svg')} />
        <div className={styles}>Already have an account?</div>
        <Button className={styles.button} color="primary" as={Link} to={routePaths.login}>
          <p>Log in</p>
        </Button>
      </div>
    )}
  </div>
);
