import React, { useState, useCallback } from 'react';
import BusinessSearch from 'components/list/BusinessSearch';
import Button from 'components/common/Button';
import useAvailableBusinessCount from './useAvailableBusinessCount';
import NewListCalculations from './NewListCalculations';
import useCreateList from './useCreateList';

const NewList = (props) => {
  const { closeForm } = props;

  const [searchParams, setSearchParams] = useState(null);
  const [sliderCount, setSliderCount] = useState(10);

  const createList = useCreateList(closeForm);
  const [count, getCount] = useAvailableBusinessCount(setSearchParams);

  const handleCreateList = useCallback(() => createList({ ...searchParams, businessesCount: sliderCount }), [
    createList,
    searchParams,
    sliderCount,
  ]);

  return (
    <>
      <BusinessSearch getCount={getCount} />
      {(count || count === 0) && (
        <>
          <NewListCalculations count={count} sliderCount={sliderCount} setSliderCount={setSliderCount} />
          <Button onClick={handleCreateList}>Apply</Button>
        </>
      )}
    </>
  );
};

export default NewList;
