import React from 'react';
import CheckboxComponent from 'rc-checkbox';
import './Checkbox.scss';
import wrapFormGroup from '../wrapFormGroup';

export const Checkbox = ({ onChange, disabled, name, value, ...props }) => {
  const handleChange = React.useCallback(
    (e) => {
      onChange(e.target.checked);
    },
    [onChange],
  );
  return <CheckboxComponent {...props} id={name} onChange={handleChange} checked={value} disabled={disabled} />;
};

export default wrapFormGroup(Checkbox);
