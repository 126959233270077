import React, { useState, useCallback } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import classnames from 'classnames';
import 'react-tabs/style/react-tabs.css';
import throttle from 'lodash/throttle';
import useLoadingData from 'hooks/useLoadingData';
import Modal, { useModal } from 'components/common/Modal';
import styles from './ListTemplates.module.scss';
import useTemplates from '../useTemplates';
import tabs from '../tabs';
import TemplateForm from '../templateForm/TemplateForm';
import TemplateTab from '../TemplateTab';

const ListTemplates = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeTemplate, setActiveTemplate] = useState(null);

  const { showModal, isVisible, hideModal } = useModal();

  const { type } = tabs[activeTab];
  const { templates, loading, fetchMore } = useTemplates(type);

  const throttledFetchMore = useCallback(throttle(fetchMore, 200), []);
  const { hasMoreData, fetchMoreData } = useLoadingData({
    key: 'templates',
    length: templates.length,
    fetchMore: throttledFetchMore,
    variables: { type },
  });

  return (
    <>
      <Tabs selectedIndex={activeTab} selectedTabClassName={styles.isSelected} onSelect={setActiveTab}>
        <TabList className={styles.tabList}>
          {tabs.map(({ title, id }) => (
            <Tab key={id} className={classnames('react-tabs__tab', styles.tabList__item)}>
              {title}
            </Tab>
          ))}
        </TabList>
        {tabs.map(({ id }) => (
          <TabPanel key={id}>
            <TemplateTab
              data={templates}
              loading={loading}
              hasMoreData={hasMoreData}
              fetchMoreTemplates={fetchMoreData}
              openTemplateForm={showModal}
              setActiveTemplate={setActiveTemplate}
            />
          </TabPanel>
        ))}
      </Tabs>
      <Modal visible={isVisible} close={hideModal}>
        <TemplateForm type={type} activeTemplate={activeTemplate} closeModal={hideModal} />
      </Modal>
    </>
  );
};

export default ListTemplates;
