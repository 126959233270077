import React from 'react';
import Colors from 'utils/chartColors';
import { BarChart as BarChartComponent, Bar } from 'recharts';

const BarChart = ({ data, keys }) => {
  return (
    <BarChartComponent width={230} height={150} data={data}>
      {keys.map((item, index) => {
        return <Bar dataKey={item} fill={Colors[index]} barSize={15} />;
      })}
    </BarChartComponent>
  );
};

export default BarChart;
