import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

export const DISCONNECT_SERVICE = gql`
  mutation($service: ServiceType!) {
    disconnectService(service: $service)
  }
`;

const useDisconnectService = () => {
  const [disconnectdMutation] = useMutation(DISCONNECT_SERVICE, {
    refetchQueries: ['me'],
  });

  const disconnectService = useCallback(
    async (service) => {
      try {
        const res = await disconnectdMutation({
          variables: { service },
        });
        return res;
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error;
        throw e;
      }
    },
    [disconnectdMutation],
  );

  return disconnectService;
};

export default useDisconnectService;
