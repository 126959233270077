import React, { useCallback, useState } from 'react';
import ForgotPasswordForm from 'components/auth/ForgotPasswordForm/ForgotPasswordForm';
import AuthPage from 'components/layout/AuthPage';
import useForgotPassword from 'hooks/auth/useForgotPassword';
import { useAlert } from 'components/common/Alert';
import SecondaryContent from './SecondaryContent';

const useForgotPasswordSubmitHandler = () => {
  const { showAlert } = useAlert();
  const [existsEmail, setExistsEmail] = useState(false);
  const forgotPassword = useForgotPassword();

  const handleSubmit = useCallback(
    async ({ email }, { resetForm }) => {
      try {
        await forgotPassword({ email });
        setExistsEmail(true);
      } catch (error) {
        showAlert({ title: error.message });
      } finally {
        resetForm();
      }
    },
    [forgotPassword, showAlert],
  );

  return { handleSubmit, existsEmail };
};

const ForgotPassword = () => {
  const { handleSubmit, existsEmail } = useForgotPasswordSubmitHandler();

  return (
    <AuthPage secondaryContent={<SecondaryContent />}>
      <ForgotPasswordForm onSubmit={handleSubmit} existsEmail={existsEmail} />
    </AuthPage>
  );
};

export default ForgotPassword;
