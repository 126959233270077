import gql from 'graphql-tag';
import { useCallback } from 'react';
import { useMutation } from 'react-apollo';

const DELETE_BUSINESS_CONTACT_FROM_LIST = gql`
  mutation removeBusinessContactFromList($contactId: ID!) {
    removeBusinessContactFromList(contactId: $contactId) {
      listId
      businessId
      contactId
    }
  }
`;

const useRemoveBusinessContact = () => {
  const [removeBusinessContactFromList] = useMutation(DELETE_BUSINESS_CONTACT_FROM_LIST, {
    refetchQueries: ['userList'],
  });

  const handleRemoveBusinessContact = useCallback(
    ({ contactId }) => {
      removeBusinessContactFromList({ variables: { contactId } });
    },
    [removeBusinessContactFromList],
  );

  return handleRemoveBusinessContact;
};

export default useRemoveBusinessContact;
