import React from 'react';
import classnames from 'classnames';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './AlertComponent.module.scss';
import Button from '../Button';

// Modal.setAppElement(document.getElementById('root'));
// Modal.setAppElement(document.body);

const customStyles = {
  content: {
    // width: '50%',
    // height: '50%',
    // top: '50%',
    // left: '50%',
    // right: 'auto',
    // bottom: 'auto',
  },
};

const AlertComponent = ({ visible, close, image = '', title, message = '', actions = [], onAfterClose, ...rest }) => (
  <Modal
    isOpen={visible}
    onRequestClose={close}
    onAfterClose={onAfterClose}
    style={customStyles}
    className={{
      base: styles.modal,
      afterOpen: styles.modalAfterOpen,
      beforeClose: styles.modalBeforeClose,
    }}
    overlayClassName={{
      base: styles.overlay,
      afterOpen: styles.overlayAfterOpen,
      beforeClose: styles.overlayBeforeClose,
    }}
    closeTimeoutMS={300}
    {...rest}
  >
    <div className={classnames(styles.modalHeader)}>
      <button type="button" className={styles.modalCloseButton} onClick={close}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
    {image ? <img src={image} className={classnames(styles.modalImage)} alt="modal icon" /> : null}
    <div className={styles.modalTitle}>{title}</div>
    <div className={styles.modalMessage}>{message}</div>
    {actions.map(({ title: buttonTitle, color, onClick, ...r }) => (
      <Button key={buttonTitle} color={color} onClick={onClick} {...r} className={styles.modalButton}>
        {buttonTitle}
      </Button>
    ))}
  </Modal>
);

export default AlertComponent;
