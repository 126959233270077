import React, { useMemo } from 'react';
import { map } from 'lodash';
import ct from 'countries-and-timezones';
import FormSelect from 'components/form/formik/Select';

const TimeZoneSelect = (props) => {
  const { form, placeholder } = props;
  const { country } = form.values;

  const timeZoneOptions = useMemo(() => {
    if (country) {
      const options = ct.getCountry(country);

      const timeZoneList = map(options.timezones, (item) => {
        const timeZone = ct.getTimezone(item);
        const { name, utcOffsetStr } = timeZone;

        return {
          label: name.concat('GMT', utcOffsetStr),
          value: name,
        };
      });
      return timeZoneList;
    }
    return [];
  }, [country]);

  return (
    <FormSelect
      options={timeZoneOptions}
      disabled={!country}
      placeholder={!country ? 'Please choose country' : placeholder}
      {...props}
    />
  );
};

export default TimeZoneSelect;
