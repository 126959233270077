import React from 'react';
import styles from './PlayIcon.module.scss';

const PlayIcon = () => {
  return (
    <div className={styles.playContainer}>
      <div className={styles.playIconCircle}>
        <div className={styles.arrow} />
      </div>
    </div>
  );
};

export default PlayIcon;
