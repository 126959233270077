import React from 'react';
import styles from './Label.module.scss';

const Label = ({ name, color, percent }) => {
  return (
    <div className={styles.container}>
      <div className={styles.colorLabel} style={{ backgroundColor: `${color}` }} />
      <div className={styles.nameLabel}>{name}</div>
      {percent && <div className={styles.percentLabel}>{percent}%</div>}
    </div>
  );
};

export default Label;
