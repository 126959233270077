/* eslint-disable react/no-danger */
import React, { useCallback } from 'react';
import { faPlus, faPencilAlt, faCopy } from '@fortawesome/free-solid-svg-icons';
import CircleButton from 'components/common/Circle-Button';
import GhostCard from 'components/common/GhostCard';
import Card from 'components/common/Card';
import InfiniteScrollComponent from 'components/InfiniteScrollComponent';
import Loading from 'components/common/Loading';
import styles from './TemplateTab.module.scss';

const TemplateCard = ({ _id, message, name, subject, attachments, openTemplateForm, setActiveTemplate }) => {
  const newAttachments = attachments.map(({ url, name: nameAttachment, size }) => ({
    url,
    name: nameAttachment,
    size,
  }));
  const handleEditTemplate = useCallback(() => {
    setActiveTemplate({ _id, message, name, subject, attachments: newAttachments });
    openTemplateForm();
  }, [_id, message, name, newAttachments, openTemplateForm, setActiveTemplate, subject]);
  return (
    <div key={_id}>
      <Card customStyle={styles.card}>
        <div className={styles.wrapperMessage} dangerouslySetInnerHTML={{ __html: message }} />
      </Card>
      <div className={styles.cardFooter}>
        <div>{name}</div>
        <div>
          <CircleButton icon={faPencilAlt} size="sm" color="danger" onClick={handleEditTemplate} />
          <CircleButton icon={faCopy} size="sm" color="primary" style={{ marginLeft: '5px' }} />
        </div>
      </div>
    </div>
  );
};

const TemplateTab = ({ data, loading, hasMoreData, fetchMoreTemplates, openTemplateForm, setActiveTemplate }) => {
  const handleNewTemplate = useCallback(() => {
    setActiveTemplate(null);
    openTemplateForm();
  }, [openTemplateForm, setActiveTemplate]);

  if (loading) return <Loading />;

  return (
    <div>
      <InfiniteScrollComponent hasMoreData={hasMoreData} fetchMoreData={fetchMoreTemplates}>
        <div className={styles.list}>
          <GhostCard onClick={handleNewTemplate} customStyle={styles.ghostCard}>
            <CircleButton icon={faPlus} size="md" color="default" />
            <b>New Template</b>
          </GhostCard>
          {data.map(({ _id, name, message, subject, attachments }) => (
            <TemplateCard
              key={_id}
              _id={_id}
              name={name}
              subject={subject}
              message={message}
              attachments={attachments}
              openTemplateForm={openTemplateForm}
              setActiveTemplate={setActiveTemplate}
            />
          ))}
        </div>
      </InfiniteScrollComponent>
    </div>
  );
};

export default TemplateTab;
