import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import classnames from 'classnames';
import styles from './AuthPage.module.scss';

// const fillHeight = { minHeight: '100%', height: '100%' };
const AuthPage = ({ children, secondaryContent }) => {
  return (
    <Container fluid>
      <Row>
        <Col md={4} className={classnames(styles.secondarySection, styles.fillHeight)}>
          <div className={styles.title}>
            <img alt="..." src={require('assets/img/logo.svg')} />
          </div>
          {secondaryContent}
        </Col>
        <Col md={8} className={classnames(styles.primarySection, styles.fillHeight)}>
          <main>{children}</main>
        </Col>
      </Row>
    </Container>
  );
};

export default AuthPage;
