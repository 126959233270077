import React from 'react';
import Button from '../../common/Button/Button';
import { ReactComponent as GoogleIcon } from '../../../assets/icons/google.svg';
import { ReactComponent as WindowsIcon } from '../../../assets/icons/windows.svg';
import styles from './EmailConnectForm.module.scss';

const EmailConnectForm = () => {
  return (
    <>
      <div className={styles.cardContainer}>
        <div className={styles.container}>
          <div className={styles.title}>Send Bulk Emails</div>
          <div className={styles.description}>Compose, personalize and schedule follow-ups.</div>
          <div className={styles.advice}>Send from your Emails account.</div>
          <div className={styles.buttonsList}>
            <div className={styles.buttonContainer}>
              <Button
                color="default"
                className={styles.button}
                onClick={() => {
                  console.log('Sign in with Google!');
                }}
              >
                <div className={styles.buttonText}>
                  <GoogleIcon style={{ marginRight: '10px' }} />
                  Sign in with Google
                </div>
              </Button>
            </div>
            <div className={styles.buttonContainer} />
            <Button
              color="default"
              className={styles.button}
              onClick={() => {
                console.log('Sign in with Outlook!');
              }}
            >
              <div className={styles.buttonText}>
                <WindowsIcon style={{ marginRight: '10px' }} /> Sign in with Outlook
              </div>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailConnectForm;
