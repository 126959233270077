import React, { useContext } from 'react';
import AlertComponent from './AlertComponent';

const AlertContext = React.createContext({
  isVisible: false,
  setIsVisible: () => {},
  setProps: () => {},
  props: {},
});

export const useAlert = () => {
  const { isVisible, setIsVisible, setProps, props } = useContext(AlertContext);
  const show = React.useCallback(
    (p) => {
      setProps({ ...p });
      setIsVisible(true);
    },
    [setIsVisible, setProps],
  );
  const hide = React.useCallback(() => {
    setIsVisible(false);
    // setProps({});
  }, [setIsVisible]);

  return { showAlert: show, hideAlert: hide, setProps, props, isVisible };
};

const Alert = () => {
  const { hideAlert, props, isVisible, setProps } = useAlert();

  const onAfterClose = React.useCallback(() => {
    setProps({});
  }, [setProps]);

  return (
    <AlertComponent
      {...props}
      visible={isVisible}
      onAfterClose={onAfterClose}
      close={hideAlert}
    />
  );
};

export const AlertProvider = ({ children }) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [props, setProps] = React.useState({});
  return (
    <AlertContext.Provider value={{ isVisible, setIsVisible, props, setProps }}>
      {children}
      <Alert />
    </AlertContext.Provider>
  );
};
