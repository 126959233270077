import _ from 'lodash';

const headers = [
  'Full name',
  'Role',
  'Email',
  'Website',
  'Business name',
  'City',
  'Full address',
  'Zip',
  'Phone Number',
  'LinkedIn',
  'Instagram',
  'Twitter',
  'Facebook',
];

const getStringifiedField = (field) => String(field === 0 ? 0 : field || '');
const arrayToCSV = (item) =>
  `${item.map((field) => `"${getStringifiedField(field).replace(/"/g, '""')}"`).toString()}\r\n`;

const businessUnitsToString = (data) => {
  // concat is faster than map=>join //http://jsben.ch/0gjMz, https://jsperf.com/map-reduce-named-functions/2
  let csvHeaders = arrayToCSV(headers);

  for (let i = 0, len = data.length; i < len; i += 1) {
    const line = data[i];
    const { contacts } = data[i];

    const businessUnit = [
      _.get(line, 'urls.webSiteUrl', ''),
      _.get(line, 'name', ''),
      _.get(line, 'location.city', ''),
      _.get(line, 'location.prettyLocation', ''),
      _.get(line, 'location.zipCode', ''),
      line &&
        line.phoneNumbers &&
        [
          _.get(line, 'phoneNumbers.local', ''),
          _.get(line, 'phoneNumbers.international', ''),
          ..._.get(line, 'phoneNumbers.fromOtherSites', ''),
        ]
          .filter(Boolean)
          .join(', '),
      _.get(line, 'social[0].link', ''),
      _.get(line, 'social[1].link', ''),
      _.get(line, 'social[2].link', ''),
      _.get(line, 'social[3].link', ''),
    ];

    for (let j = 0, size = contacts.length; j < size; j += 1) {
      const con = contacts[j];
      const contactData = [_.get(con, 'fullName', ''), _.get(con, 'role', ''), _.get(con, 'email', '')].concat(
        businessUnit,
      );
      csvHeaders = csvHeaders.concat(arrayToCSV(contactData));
    }
  }
  return csvHeaders;
};

export default businessUnitsToString;
