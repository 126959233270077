import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

const useForgotPassword = () => {
  const [forgotPasswordMutation] = useMutation(FORGOT_PASSWORD);

  const forgotPassword = useCallback(
    async ({ email }) => {
      try {
        await forgotPasswordMutation({ variables: { email } });
        return;
      } catch (error) {
        const e = error.graphQLErrors
          ? new Error(error.graphQLErrors.map((err) => err.message))
          : error;
        e.raw = error;
        throw e;
      }
    },
    [forgotPasswordMutation],
  );

  return forgotPassword;
};

export default useForgotPassword;
