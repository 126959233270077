import React from 'react';
import styles from './PresentationCard.module.scss';
import CircleButton from '../Circle-Button';

const PresentationCard = ({ title, button, image, info, footer }) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        {title && <div className={styles.cardHeader__title}>{title}</div>}
        {button && (
          <CircleButton
            icon={button.icon}
            size="sm"
            color="danger"
            onClick={button.onClick}
            className={styles.cardHeader__button}
          />
        )}
      </div>
      <img src={image.src} alt={image.alt} className={styles.cardImage} />
      {info && <div>{info}</div>}
      <div className={styles.card__footer}>{footer}</div>
    </div>
  );
};

export default PresentationCard;
