import React from 'react';
import LineProgressBar from 'components/common/ProgressBar/Line';
import { faUserAlt, faClock, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import useUser from 'hooks/useUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'rc-dropdown';
import Menu from 'rc-menu';
import classnames from 'classnames';
import 'rc-dropdown/assets/index.css';
import styles from './UserDropdown.module.scss';

const DropdownMenuItem = ({ children }) => (
  <li className={classnames('rc-dropdown-menu-item', styles.dropdown__menuItem)}>{children}</li>
);

const DropdownMenu = ({ email, subscription }) => {
  const {
    businessesUsed,
    businessesTotal,
    requestsUsed,
    requestsTotal,

    renewalDate,
  } = subscription;

  return (
    <Menu className={styles.dropdown__menu}>
      <DropdownMenuItem>
        <FontAwesomeIcon icon={faUserAlt} /> Signed in as: <span>{email}</span>
      </DropdownMenuItem>
      <DropdownMenuItem>
        <FontAwesomeIcon icon={faClock} /> Next renewal: <span>{renewalDate}</span>
      </DropdownMenuItem>
      <DropdownMenuItem>
        Businesses used:
        <div className={styles.progressBar}>
          <LineProgressBar percent={(businessesUsed / businessesTotal) * 100} />
          <span>{`${businessesUsed}/${businessesTotal}`}</span>
        </div>
      </DropdownMenuItem>
      <DropdownMenuItem>
        Requests used:
        <div className={styles.progressBar}>
          <LineProgressBar percent={(requestsUsed / requestsTotal) * 100} />
          <span>{`${requestsUsed}/${requestsTotal}`}</span>
        </div>
      </DropdownMenuItem>
    </Menu>
  );
};

const UserDropdown = ({ subscription }) => {
  const [me] = useUser();
  const { fullName, avatar, email } = me;
  const userAvatar = avatar || require('assets/img/user.svg');

  const [visible, setVisible] = React.useState(false);
  const onVisibleChange = () => setVisible(!visible);

  return (
    <Dropdown
      trigger={['click']}
      overlay={DropdownMenu({ email, subscription })}
      animation="slide-up"
      onVisibleChange={onVisibleChange}
    >
      <div className={styles.dropdown}>
        <img src={userAvatar} alt="avatar" className={styles.dropdown__avatar} />
        <span className={styles.dropdown__fullname}>{fullName} </span>
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
    </Dropdown>
  );
};

export default UserDropdown;
