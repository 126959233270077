import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-grid-system';
import Button from 'components/common/Button';
import useLogout from 'hooks/auth/useLogout';
import useSendActivateEmail from 'hooks/auth/useSendActivateEmail';
import styles from './ActivateAccount.module.scss';

const ActivateAccount = () => {
  const [handleSendActivateAccountEmail, loading] = useSendActivateEmail();
  const [isResend, setIsResend] = useState(true);
  const logout = useLogout();

  const sendActivate = () => {
    setIsResend(false);
    handleSendActivateAccountEmail();
    setTimeout(() => {
      setIsResend(true);
    }, 5000);
  };

  return (
    <Container fluid style={{ marginTop: '8%' }}>
      <Row justify="center" className={styles.logoContainer}>
        <Col xs={4} className={styles.logoImage}>
          <img src={require('assets/img/boost-logo.svg')} />
        </Col>
      </Row>
      <Row justify="center" className={styles.allInfo}>
        <Col md={12} className={styles.info}>
          <div>ALMOST THERE...</div>
        </Col>
        <Col md={12} className={styles.infoText}>
          <div>Go to your inbox and click the activation link to open your Boost account.</div>
        </Col>
      </Row>
      <Row justify="center">
        <div className={styles.buttonContainer}>
          <Button
            key="resend-link"
            color="secondary"
            loading={loading}
            onClick={sendActivate}
            disabled={!isResend}
            style={!isResend ? { textDecoration: 'line-through' } : {}}
          >
            Resend link
          </Button>
          <Button key="resend-link" color="primary" onClick={logout} style={{ marginTop: '5px' }}>
            Log out
          </Button>
        </div>
      </Row>
      <Row justify="center" className={styles.terms}>
        <Col md={4} className={styles.termsInfo}>
          <div>Boost(c)2020. All rights reserved.</div>
          <div className={styles.links}>
            <Link to="/login">Terms & Conditions</Link>
            <Link to="/login">Privacy Policy</Link>
            <Link to="/login">Contact</Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ActivateAccount;
