import React from 'react';
import Button from 'components/common/Button';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';

const ACCEPT_CONSENT = gql`
  mutation {
    acceptConsent
  }
`;

// const useAcceptConsent = () => {
// };

const TermsAndPolicyForm = () => {
  const [accept] = useMutation(ACCEPT_CONSENT, { refetchQueries: ['me'] });
  // TODO: Add Terms and policy links
  return (
    <div>
      <h2>Please confirm that you are agreeing with Terms & Conditions and Privacy Policy</h2>
      <Button color="primary" onClick={accept}>
        Confirm
      </Button>
    </div>
  );
};

export default TermsAndPolicyForm;
