import { useMemo, useState, useCallback } from 'react';
import { useDebounce } from 'use-debounce';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import businessUnitsToString from 'components/list/csvExport';
import dataToXlsx from 'components/list/xlsxExport';

const fileDownload = require('react-file-download');

export const LIST_FRAGMENT = gql`
  fragment list on List {
    _id
    createdAt
    category
    location {
      city
      country
    }
    businessUnits {
      _id
      name
      location {
        city
        country
        countryCode
        address
        prettyLocation
        zipCode
      }
      phoneNumbers {
        local
      }
      urls {
        webSiteUrl
      }
      social {
        type
        link
      }
      contacts {
        _id
        email
        fullName
      }
      rating
      reviewCount
    }
    isArchived
  }
`;

export const GET_LIST_ITEM_QUERY = gql`
  query userList($id: ID!) {
    userList(id: $id) {
      ...list
    }
  }
  ${LIST_FRAGMENT}
`;

const useListItemInfo = (listId) => {
  const [search, setSearch] = useState('');
  const { data, loading } = useQuery(GET_LIST_ITEM_QUERY, {
    skip: !listId,
    variables: {
      id: listId,
    },
  });
  const [updatedSearch] = useDebounce(search, 500);

  const handleChange = useCallback((value) => {
    setSearch(value);
  }, []);

  const list = useMemo(() => get(data, 'userList', null), [data]);

  const filteredUnits = useMemo(
    () =>
      _.filter(list && list.businessUnits, (unit) => {
        const { name, urls } = unit;
        const { webSiteUrl } = urls;
        return (
          name.toLowerCase().includes(updatedSearch.toLowerCase()) ||
          webSiteUrl.toLowerCase().includes(updatedSearch.toLowerCase())
        );
      }),
    [list, updatedSearch],
  );

  const handleCSVExport = useCallback(() => {
    const csv = businessUnitsToString(filteredUnits);
    fileDownload(csv, 'Business units.csv');
  }, [filteredUnits]);

  const handleXlsxExport = useCallback(() => {
    dataToXlsx(filteredUnits);
  }, [filteredUnits]);

  return {
    list: list ? { ...list, businessUnits: filteredUnits } : null,
    loading,
    handleChange,
    handleCSVExport,
    handleXlsxExport,
  };
};

export default useListItemInfo;
