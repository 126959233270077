/* eslint-disable no-console */
import React, { useCallback } from 'react';
import useUpdateProfileHandler from 'components/profile/useUpdateProfileHandler';
import useUser from 'hooks/useUser';
import { toast } from 'react-toastify';
import useDisconnectService from 'hooks/useDisconnectService';
import useLinkedInAuth from 'hooks/auth/useLinkedInAuth';
import useGoogleAuthButton from 'hooks/auth/useGoogleAuthButton';
import useFacebookAuth from 'hooks/auth/useFacebookAuth';
import { useAlert } from 'components/common/Alert';
import ContactForm from './ContactForm';

const useConnectServiceHandler = () => {
  const [authWithGoogle] = useGoogleAuthButton();
  const [authWithLinkedIn] = useLinkedInAuth();
  const [authWithFacebook] = useFacebookAuth();

  const handleSubmitConnect = useCallback(
    (service) => {
      try {
        switch (service) {
          case 'facebook':
            authWithFacebook();
            break;
          case 'linked_in':
            authWithLinkedIn();
            break;
          case 'google':
            authWithGoogle();
            break;
          default:
            break;
        }
      } catch (error) {
        toast.error(error);
      }
    },
    [authWithFacebook, authWithLinkedIn, authWithGoogle],
  );

  return handleSubmitConnect;
};

const useDisconnectServiceHandler = (services) => {
  const disconnectService = useDisconnectService();
  const { showAlert, hideAlert } = useAlert();

  const handleSubmitDisconnect = useCallback(
    async (service) => {
      try {
        if (services.length === 1) {
          toast.error("You can't disable last auth service");
        } else {
          showAlert({
            title: 'Are you sure you want to disable the service?',
            actions: [
              {
                title: 'Cancel',
                color: 'cancel',
                onClick: () => hideAlert(),
              },
              {
                title: 'Disable',
                color: 'secondary',
                onClick: async () => {
                  await disconnectService(service);
                  toast.success('Service was successfully disconnected');
                  hideAlert();
                },
              },
            ],
          });
        }
      } catch (error) {
        toast.error(error);
      }
    },
    [disconnectService, hideAlert, services.length, showAlert],
  );

  return handleSubmitDisconnect;
};

const ContactTab = () => {
  const [me] = useUser();
  const { businessEmail, phone, services } = me;
  const { code, number } = phone || {};

  const initialValues = {
    businessEmail: businessEmail || '',
    number: number || '',
    code,
  };

  const { handleSubmit, response } = useUpdateProfileHandler();

  const handleSubmitDisconnect = useDisconnectServiceHandler(services);
  const handleSubmitConnect = useConnectServiceHandler();

  return (
    <ContactForm
      disconnectService={handleSubmitDisconnect}
      connectService={handleSubmitConnect}
      onSubmit={handleSubmit}
      services={services}
      initialValues={initialValues}
      response={response}
    />
  );
};

export default ContactTab;
