import React, { useState } from 'react';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextInput from 'components/form/formik/TextInput';
import styles from './PasswordInput.module.scss';

const PasswordInput = ({ name, ...props }) => {
  const [isShowPassword, toggleShowPassword] = useState(false);

  const handleTogglePassword = () => {
    toggleShowPassword(!isShowPassword);
  };

  return (
    <div className={styles.inputContainer}>
      <TextInput type={isShowPassword ? 'text' : 'password'} {...props} />
      <FontAwesomeIcon
        icon={isShowPassword ? faEyeSlash : faEye}
        onClick={handleTogglePassword}
        className={styles.iconShowingPassword}
      />
    </div>
  );
};

export default PasswordInput;
