import React, { useCallback } from 'react';
import AuthPage from 'components/layout/AuthPage';
import ResetPasswordForm from 'components/auth/ResetPasswordForm/ResetPasswordForm';
import useResetPassword from 'hooks/auth/useResetPassword';
import routePaths from 'router/route-paths';
import useQueryParams from 'hooks/useQueryParams';
import { useAlert } from 'components/common/Alert';
import { useHistory } from 'react-router-dom';
import SecondaryContent from './SecondaryContent';

const useResetPasswordSubmitHandler = ({ token }) => {
  const { showAlert, hideAlert } = useAlert();
  const history = useHistory();
  const resetPassword = useResetPassword();

  const handleSubmit = useCallback(
    async ({ password }, { resetForm }) => {
      try {
        await resetPassword({ token, password });
        showAlert({
          title: 'Password successfully changed',
          actions: [
            {
              title: 'Back to Login',
              onClick: () => {
                history.push(routePaths.login);
                hideAlert();
              },
            },
          ],
        });
      } catch (error) {
        showAlert({ title: error.message });
      } finally {
        resetForm();
      }
    },
    [hideAlert, history, resetPassword, showAlert, token],
  );

  return handleSubmit;
};

const ResetPassword = () => {
  const { token } = useQueryParams();
  const handleSubmit = useResetPasswordSubmitHandler({ token });

  return (
    <AuthPage secondaryContent={<SecondaryContent />}>
      <ResetPasswordForm onSubmit={handleSubmit} />
    </AuthPage>
  );
};

export default ResetPassword;
