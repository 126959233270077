import { useCallback } from 'react';
import get from 'lodash/get';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { useAlert } from 'components/common/Alert';

export const SEND_ACTIVATE_EMAIL_MUTATION = gql`
  mutation sendActivateAccountEmail {
    sendActivateAccountEmail
  }
`;

const useSendActivateEmail = () => {
  const { showAlert } = useAlert();

  const [sendActivateAccountEmail, { loading }] = useMutation(SEND_ACTIVATE_EMAIL_MUTATION, {
    onCompleted: () => {
      showAlert({ title: 'Email sent' });
    },
  });

  const handleSendActivateEmail = useCallback(async () => {
    try {
      await sendActivateAccountEmail();
    } catch (error) {
      const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
      showAlert({ title: get(error, 'graphQLErrors[0].message') });
      e.raw = error;
      throw e;
    }
  }, [sendActivateAccountEmail, showAlert]);

  return [handleSendActivateEmail, loading];
};

export default useSendActivateEmail;
