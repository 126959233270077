import React from 'react';
import * as yup from 'yup';
import { Formik, Field, Form } from 'formik';
// import AuthInput from 'components/auth/AuthInput';
import Button from 'components/common/Button';
// reactstrap components
import FormFeedback from 'components/form/FormFeedback';
import TextInput from 'components/form/formik/TextInput';
import styles from './ResetPasswordForm.module.scss';

const resetPasswordShape = {
  password: yup
    .string()
    .required('Password is required.')
    .min(8, 'Password should be at least 8 characters.'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
};

const resetPasswordSchema = yup.object().shape(resetPasswordShape);

const initialValues = { password: '', passwordConfirmation: '' };

const ResetPasswordForm = ({ onSubmit }) => {
  return (
    <>
      <div>
        <Formik initialValues={initialValues} validationSchema={resetPasswordSchema} onSubmit={onSubmit}>
          {({ isSubmitting, errors, touched }) => (
            <>
              <div>
                <div className={styles.title}>Reset your password</div>
                <Form className={styles.form}>
                  <Field
                    label="New Password"
                    type="password"
                    name="password"
                    component={TextInput}
                    invalid={!!(touched.password && errors.password)}
                  />
                  <Field
                    label="Confirm New Password"
                    type="password"
                    name="passwordConfirmation"
                    component={TextInput}
                    invalid={!!(touched.passwordConfirmation && errors.passwordConfirmation)}
                  />
                  <FormFeedback valid={false} style={{ display: 'block' }}>
                    {errors.form}
                  </FormFeedback>
                  <div className={styles.buttonContainer}>
                    <Button
                      className={styles.button}
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      color="secondary"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </div>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ResetPasswordForm;
