import React from 'react';
import Button from 'components/common/Button';
import * as yup from 'yup';
import AsyncSelect from 'components/form/formik/AsyncSelect';
import Select from 'components/form/formik/Select';
import FormFeedback from 'components/form/FormFeedback';
import { Formik, Field, Form } from 'formik';
import Modal, { useModal } from 'components/common/Modal';
import useSearchCountries from './useSearchCountries';
import useSearchCategories from './useSearchCategories';
import CitySearchSelect from './CitySearchSelect';
import styles from './BusinessSearch.module.scss';
import SpecialBusinessSearchRequest from '../SpecialBusinessSearchRequest';

const initialValues = {
  country: null,
  city: null,
  category: null,
};

// const promiseSubmit = async (values) => {
//   console.log('values => ', values);
//   await new Promise((resolve) => {
//     setTimeout(() => resolve(), 1000);
//   });
//   return true;
// };

const selectStyles = {
  option: (provided) => ({
    ...provided,
    fontSize: '14px',
    color: '#33325C',
  }),
  control: (provided, { isDisabled, isFocused, selectProps: { name } }) => ({
    ...provided,
    borderRadius: name === 'country' ? '50px 0px 0px 50px' : 'none',
    border: 'none',
    background: isDisabled ? '#F4F6FA' : '#F9F9F9',
    height: '50px',
    fontSize: '13px',
    paddingLeft: '10px',
    width: '170px',
    outline: 'none',
    borderRight: '1px solid #DCDFEE!important',
    boxShadow: isFocused ? 0 : 0,
    '&:hover': {
      border: isFocused ? 0 : 0,
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#BFC3D7',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#33325C',
    padding: '0 20px 0 0',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
};

const contactShape = {
  country: yup
    .string()
    .nullable()
    .required('Country is required to perform search'),
  city: yup
    .string()
    .nullable()
    .required('City is required to perform search'),
  category: yup
    .string()
    .nullable()
    .required('Category is required to perform search'),
};

const schema = yup.object().shape(contactShape);

const BusinessSearch = (props) => {
  const { getCount } = props;
  const { showModal, hideModal, isVisible } = useModal();
  const [countryOptions, countryOptionsLoading] = useSearchCountries();
  const searchCategories = useSearchCategories();

  return (
    <>
      <Formik onSubmit={getCount} initialValues={initialValues} validationSchema={schema}>
        {({ isSubmitting, errors, touched, setFieldValue, setFieldTouched }) => (
          <>
            <Form className={styles.container}>
              <Field
                name="country"
                hideError
                component={Select}
                options={countryOptions}
                isLoading={countryOptionsLoading}
                placeholder="Select country"
                onChange={() => {
                  setFieldValue('city', null);
                  setFieldTouched('city', false);
                }}
                styles={selectStyles}
              />
              <Field
                styles={selectStyles}
                name="city"
                hideError
                component={CitySearchSelect}
                placeholder="Select city"
              />
              <Field
                hideError
                defaultOptions
                name="category"
                component={AsyncSelect}
                loadOptions={searchCategories}
                placeholder="Select category"
                styles={selectStyles}
              />
              <Button className={styles.buttonSearch} loading={isSubmitting} type="submit">
                Search
              </Button>
            </Form>
            <div className={styles.requestInfo}>
              <div className={styles.message}>
                If you can not find what you are looking for, make a custom request. We will do that without any
                additional charge for you!{' '}
                <a className={styles.openModalBtn} onClick={showModal}>
                  Request
                </a>
              </div>
            </div>
            {Object.entries(errors).map(([key, error]) => {
              if (touched[key])
                return (
                  <FormFeedback key={key}>
                    {error}
                    <br />
                  </FormFeedback>
                );
              return null;
            })}
          </>
        )}
      </Formik>
      <div>
        <Modal visible={isVisible} close={hideModal}>
          <SpecialBusinessSearchRequest closeForm={hideModal} />
        </Modal>
      </div>
    </>
  );
};

export default BusinessSearch;
