import _ from 'lodash';

const XlsxPopulate = require('xlsx-populate');

const dataHeaders = [
  [
    'Full name',
    'Role',
    'Email',
    'Website',
    'Business name',
    'City',
    'Full address',
    'Zip',
    'Phone Number',
    'LinkedIn',
    'Instagram',
    'Twitter',
    'Facebook',
  ],
];

const businessHeaders = [
  [
    'Website',
    'Business name',
    'City',
    'Full address',
    'Zip',
    'Phone Number',
    'LinkedIn',
    'Instagram',
    'Twitter',
    'Facebook',
  ],
];

/* eslint-disable */

const dataHeadersWidthConfig = [
  25,   // Full name
  10,   // Role
  26,   // Email
  20,   // Website
  20,   // Business name
  10,   // City
  35,   // Full address
  8,    // Zip
  15,   // Phone number
  20,   // LinkedIn
  20,   // Twitter
  20,   // Facebook
];

const businessHeadersWidthConfig = [
  20,   // Website
  20,   // Business name
  10,   // City
  35,   // Full address
  8,    // Zip
  15,   // Phone number
  20,   // LinkedIn
  20,   // Twitter
  20,   // Facebook
];

/* eslint-enable */

const headerStyle = {
  bold: true,
  border: true,
  verticalAlignment: 'center',
  horizontalAlignment: 'center',
  borderColor: '8B6BDB',
  fill: 'D7CBF4',
};

const dataToXls = (data) => {
  const values = [];
  const units = [];

  for (let i = 0, len = data.length; i < len; i += 1) {
    const line = data[i];
    const { contacts } = data[i];

    const businessUnit = [
      _.get(line, 'urls.webSiteUrl', ''),
      _.get(line, 'name', ''),
      _.get(line, 'location.city', ''),
      _.get(line, 'location.prettyLocation', ''),
      _.get(line, 'location.zipCode', ''),
      line &&
        line.phoneNumbers &&
        [
          _.get(line, 'phoneNumbers.local', ''),
          _.get(line, 'phoneNumbers.international', ''),
          ..._.get(line, 'phoneNumbers.fromOtherSites', ''),
        ]
          .filter(Boolean)
          .join(', '),
      _.get(line, 'social[0].link', ''),
      _.get(line, 'social[1].link', ''),
      _.get(line, 'social[2].link', ''),
      _.get(line, 'social[3].link', ''),
    ];

    units.push(businessUnit);

    for (let j = 0, size = contacts.length; j < size; j += 1) {
      const con = contacts[j];
      const contactData = [_.get(con, 'fullName', ''), _.get(con, 'role', ''), _.get(con, 'email', '')].concat(
        businessUnit,
      );
      values.push(contactData);
    }
  }

  return XlsxPopulate.fromBlankAsync().then((workbook) => {
    // Modify the sheet 0

    workbook.addSheet('Business list');
    workbook.deleteSheet(0);

    workbook
      .sheet(0)
      .cell('A1')
      .value(dataHeaders)
      .style(headerStyle);

    workbook
      .sheet(0)
      .cell('A2')
      .value(values);

    // setting the width of the data columns
    dataHeadersWidthConfig.map((width, index) =>
      workbook
        .sheet(0)
        .column(index + 1)
        .width(width),
    );

    // Modify the sheet 1

    workbook.addSheet('Business units');

    workbook
      .sheet(1)
      .cell('A1')
      .value(businessHeaders)
      .style(headerStyle);

    workbook
      .sheet(1)
      .cell('A2')
      .value(units);

    // setting the width of the data columns
    businessHeadersWidthConfig.map((width, index) =>
      workbook
        .sheet(1)
        .column(index + 1)
        .width(width),
    );

    const fileName = 'Business units.xlsx';
    workbook.outputAsync().then((blob) => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        const url = window.URL.createObjectURL(blob);
        const a = window.document.createElement('a');
        window.document.body.appendChild(a);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        window.document.body.removeChild(a);
      }
    });
  });
};

export default dataToXls;
