/* eslint-disable new-cap */
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormFeedback from 'components/form/FormFeedback';
import TextInput from 'components/form/formik/TextInput';
import Button from 'components/common/Button';
import useUpsertTemplate from '../useUpsertTemplate';
import styles from './TemplateForm.module.scss';
import Editor from './Editor';
import FilesField from './FilesFiled';

const templateShape = {
  name: yup.string().required(),
  subject: yup.string().required(),
  message: yup.string().required(),
};

const defaultInitialValues = {
  name: '',
  subject: '',
  message: '',
  attachments: [],
};

const schema = yup.object().shape(templateShape);

const TemplateForm = ({ type, activeTemplate, closeModal }) => {
  const { _id: templateId, ...rest } = activeTemplate || {};
  const values = useMemo(() => ({ ...defaultInitialValues, ...rest }), [rest]);
  const submit = useUpsertTemplate(type, templateId, closeModal);

  return (
    <>
      <Formik initialValues={values} validationSchema={schema} onSubmit={submit}>
        {({ isSubmitting, errors }) => (
          <>
            <div className={styles.container}>
              <hr />
              <Form className={styles.form}>
                <div className={styles.formGroup}>
                  <div className={styles.label}>Template name</div>
                  <Field name="name" component={TextInput} placeholder="Template name" className={styles.input} />
                </div>
                <div className={styles.formGroup}>
                  <div className={styles.label}>Subject</div>
                  <Field name="subject" component={TextInput} placeholder="Template subject" className={styles.input} />
                </div>
                <div className={styles.formGroup}>
                  <Field name="message" component={Editor} />
                </div>
                <Field name="files" component={FilesField} />
                <FormFeedback valid={false} style={{ display: 'block' }}>
                  {errors.form}
                </FormFeedback>
                <div className={styles.buttonContainer}>
                  <Button
                    className={styles.buttonSubmit}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    color="secondary"
                    type="submit"
                  >
                    Save
                    <FontAwesomeIcon icon={faSave} className={styles.buttonIcon} />
                  </Button>
                </div>
              </Form>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default TemplateForm;
