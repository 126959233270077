import React, { useState } from 'react';
import classnames from 'classnames';
import { ReactComponent as IconYes } from 'assets/icons/ok.svg';
import { ReactComponent as IconNo } from 'assets/icons/cancel.svg';
import { ReactComponent as IconInfinity } from 'assets/icons/infinity.svg';
import { ReactComponent as IconInfo } from 'assets/icons/info.svg';
import { ReactComponent as TipArrow } from 'assets/icons/tip.svg';
import { ReactComponent as IconDiamond } from 'assets/icons/diamond.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import Button from 'components/common/Button';
import ReactTooltip from 'react-tooltip';
import Collapse, { Panel } from 'rc-collapse';
import 'rc-collapse/assets/index.css';
import { map } from 'lodash';
import { PlanHeadings, PlanRows, plans } from './PricingData';
import styles from './PricingPlans.module.scss';
import './collapse.css';
import './PricingPlans.scss';

const PricingPlans = ({ upgradePlan, product, period }) => {
  const [isMonthly, updateIsMonthly] = useState(1);
  const toggleTerm = () => {
    updateIsMonthly(!isMonthly);
  };

  const isActivePlan = (name) =>
    product === name && ((isMonthly && period === 'monthly') || (!isMonthly && period === 'yearly'));

  const getPlanName = (planName) => `${isMonthly ? 'm' : 'y'}-${planName}`;

  const PricingHeaderRow = () => {
    return (
      <div className={classnames(styles.planRow, styles.planRowHeader)}>
        <div className={classnames(styles.planCol, styles.planColLabel)} />
        {PlanHeadings.map(({ name, title, logo, highlight }) => {
          const popularLabel = highlight ? <div className={styles.popularLabel}>Most popular</div> : null;
          return (
            <div key={title} className={classnames(styles.planCol, isActivePlan(name) && styles.muted)}>
              {popularLabel}
              <div className={styles.planTitle}>{title}</div>
              <div className={styles.planLogo}>{logo}</div>
              <div className={styles.planPrice}>${isMonthly ? plans[name].price : plans[name].yearlyPrice}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const UpgradeButton = ({ name }) => (
    <Button className={styles.btn} onClick={() => upgradePlan(getPlanName(name))}>
      <div className={styles.btnContent}>
        <div>Upgrade</div>
        <div className={styles.circle}>
          <ArrowRight className={styles.arrow} />
        </div>
      </div>
    </Button>
  );

  const CurrentPlan = () => {
    return (
      <div className={styles.currentPlan}>
        <Check />
        <div className={styles.message}>Your current plan</div>
      </div>
    );
  };

  const PricingFooterRow = () => {
    return (
      <div className={classnames(styles.planRow, styles.planRowFooter)}>
        <div className={classnames(styles.planCol, styles.planColLabel)} />
        {PlanHeadings.map(({ title, name }) => {
          return (
            <div key={title} className={styles.planCol}>
              <div className={styles.planBtn}>
                {isActivePlan(name) ? <CurrentPlan /> : <UpgradeButton name={name} />}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const Row = ({ id, isRowGroup, label, name, tip }) => {
    const tipView = tip !== '' ? <Tip tip={tip} id={id} /> : null;
    return (
      <div key={id} className={classnames(styles.planRow, isRowGroup && styles.isRowGroup)}>
        {/* 1st col */}
        <div className={classnames(styles.planCol, styles.planColLabel)}>
          <div className={styles.labelWrapper}>
            <span className={styles.labelText}>{label}</span>
            {tipView}
          </div>
        </div>
        {/* other cols */}
        {map(plans, (plan, key) => {
          const val = plan[name];

          let planVal;
          if (typeof val === 'boolean') {
            planVal = val ? <IconYes width={15} /> : <IconNo width={15} />;
          } else if (typeof val === 'number') {
            if (val === -1) {
              planVal = <IconInfinity width={20} />;
            } else {
              planVal = val;
            }
          } else {
            planVal = val;
          }
          const diamond = key === 'pro' && isRowGroup ? <IconDiamond className={styles.icon} width={10} /> : null;
          return (
            <div
              key={key}
              className={classnames(
                styles.planCol,
                styles.planColValue,
                key === 'pro' && styles.planColPopular,
                isActivePlan(key) && styles.muted,
              )}
            >
              {planVal}
              {diamond}
            </div>
          );
        })}
      </div>
    );
  };

  const Tip = ({ tip, id }) => {
    const tipId = `tooltip${id}`;
    return (
      <span className={styles.tipView}>
        <span className={styles.icon} data-tip data-for={tipId} data-event="click">
          <IconInfo />
        </span>
        <ReactTooltip id={tipId} place="right" effect="solid" globalEventOff="click" clickable className="tipWrapper">
          <span className={styles.tip}>
            <TipArrow className={styles.tipArrow} />
            <span className={styles.tipContent}>{tip}</span>
          </span>
        </ReactTooltip>
      </span>
    );
  };

  return (
    <section className={styles.sectionPricingPlans}>
      <div className={styles.plansTerms}>
        <Button
          color={isMonthly ? 'danger' : 'whitePale'}
          className={styles.planTermsButton}
          onClick={!isMonthly && toggleTerm}
        >
          <strong>Monthly</strong>
        </Button>
        <Button
          color={!isMonthly ? 'danger' : 'whitePale'}
          className={styles.planTermsButton}
          onClick={isMonthly && toggleTerm}
        >
          <strong>Yearly</strong> (Save 30%)
        </Button>
      </div>

      <div className={styles.planTableWrapper}>
        <div className={styles.planTableInner}>
          <div className={styles.planTable}>
            <PricingHeaderRow />
            <Collapse expandIcon={({ isActive }) => <Arrow isActive={isActive} />}>
              {PlanRows.map(({ id, label, tip, isRowGroup, name, items }) => {
                return isRowGroup ? (
                  <Panel key={id} header={<Row id={id} label={label} tip={tip} isRowGroup={isRowGroup} name={name} />}>
                    {items.map((item) => (
                      <Row {...item} />
                    ))}
                  </Panel>
                ) : (
                  <Row key={id} id={id} label={label} tip={tip} isRowGroup={isRowGroup} name={name} />
                );
              })}
            </Collapse>
            <PricingFooterRow />
          </div>
        </div>
      </div>
    </section>
  );
};

function Arrow({ isActive }) {
  return (
    <div className={styles.arrowContainer}>
      <ChevronDownIcon className={!isActive ? styles.arrow : styles.activeArrow} />
    </div>
  );
}

export default PricingPlans;
