import React from 'react';
import AppPage from 'components/layout/AppPage';
import ListTemplates from 'components/templates/ListTemplates/ListTemplates';

const Templates = () => {
  return (
    <AppPage>
      <ListTemplates />
    </AppPage>
  );
};

export default Templates;
