import config from './config';

const hideRecaptcha = process.env.REACT_APP_HIDE_RECAPTCHA;

export default {
  ...config,
  isDevelopment: process.env.NODE_ENV !== 'production',
  isProduction: process.env.NODE_ENV === 'production',
  graphqlServerUrl: process.env.REACT_APP_GRAPHQL_SERVER_URL,
  debug: process.env.REACT_APP_DEBUG,
  recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  hideRecaptcha: hideRecaptcha ? !!JSON.parse(hideRecaptcha) : false,
};
