/* eslint-disable no-underscore-dangle */
import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import ReportForm from './ReportForm';
import styles from './ReportForm.module.scss';
import useSendReport from './useSendReport';

const BusinessUnitRow = ({ data }) => {
  const {
    name,
    location: { city },
    urls: { webSiteUrl },
    phoneNumbers: { local },
  } = data;
  return (
    <div className={styles.row}>
      <div>{webSiteUrl}</div>
      <div>{name}</div>
      <div>{city}</div>
      <div>{local}</div>
    </div>
  );
};

const ContactRow = ({ data }) => {
  const { fullName, email } = data;
  return (
    <div className={styles.row}>
      <div>{fullName}</div>
      <div>{email}</div>
    </div>
  );
};

const getRowByType = (type, data) => {
  switch (type) {
    case 'contact':
      return <ContactRow data={data} />;
    case 'businessUnit':
      return <BusinessUnitRow data={data} />;
    default:
      return null;
  }
};

const useSendReportSubmitHandler = ({ type, id, hideModal }) => {
  const sendReport = useSendReport(type, id);
  const [response, setResponse] = useState({
    isError: false,
    message: '',
  });

  const handleSubmit = useCallback(
    async (variables) => {
      try {
        await sendReport(variables);
        setResponse({ isError: false, message: 'Report has sent successfully' });
        hideModal();
        toast.success('The report was sent successfully');
      } catch (error) {
        setResponse({ isError: true, message: error.message });
      }
    },
    [hideModal, sendReport],
  );

  return { handleSubmit, response };
};

const Report = ({ type, data, onCancel }) => {
  const row = getRowByType(type, data);
  const id = _.get(data, '_id', null);
  const { handleSubmit, response } = useSendReportSubmitHandler({ type, id, hideModal: onCancel });

  return <ReportForm onSubmit={handleSubmit} row={row} onCancel={onCancel} response={response} />;
};

export default Report;
