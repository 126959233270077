import { useCallback } from 'react';
import gql from 'graphql-tag';
import axios from 'axios';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';

export const UPLOAD_FILE = gql`
  mutation($fileName: String!, $fileType: String!) {
    signS3(fileName: $fileName, fileType: $fileType) {
      url
      signedRequest
    }
  }
`;

const formatFilename = (filename) => {
  const date = moment().format('YYYYMMDD');
  const randomString = Math.random()
    .toString(36)
    .substring(2, 7);
  const cleanFileName = filename.toLowerCase().replace(/[^a-z0-9]/g, '-');
  const newFilename = `${date}-${randomString}-${cleanFileName}`;
  return newFilename.substring(0, 60);
};

const uploadToS3 = async (fileDoc, signedRequest) => {
  const options = {
    headers: {
      'Content-Type': fileDoc.type,
    },
  };
  await axios.put(signedRequest, fileDoc, options);
};

const useUploadFile = (file) => {
  const [uploadFileMutation] = useMutation(UPLOAD_FILE);

  const uploadFile = useCallback(async () => {
    const { name, type: fileType } = file.current;
    const fileName = formatFilename(name);

    try {
      const {
        data: {
          signS3: { signedRequest, url },
        },
      } = await uploadFileMutation({
        variables: { fileName, fileType },
      });
      await uploadToS3(file.current, signedRequest);
      return url;
    } catch (error) {
      const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
      e.raw = error;
      throw e;
    }
  }, [file, uploadFileMutation]);

  return uploadFile;
};

export default useUploadFile;
