import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { toast } from 'react-toastify';

const SUBSCRIBE = gql`
  mutation($plan: String!, $paymentMethod: String!) {
    subscribe(plan: $plan, paymentMethod: $paymentMethod) {
      _id
    }
  }
`;

export const useUpgradePlanHandler = () => {
  const subscribe = useSubscribe();
  const handleUpgrage = useCallback(
    async (plan) => {
      try {
        await subscribe(plan);
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error;
        toast.error(e.message);
      }
    },
    [subscribe],
  );
  return handleUpgrage;
};

// TODO: Make proper subscription
const useSubscribe = () => {
  const [subscribe] = useMutation(SUBSCRIBE, {
    refetchQueries: ['me'],
  });

  const handleSubscribe = useCallback(
    async (plan) => {
      await subscribe({ variables: { plan, paymentMethod: 'mock_payment_method' } });
    },
    [subscribe],
  );

  return handleSubscribe;
};

export default useSubscribe;
