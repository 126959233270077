import React from 'react';
import { Line } from 'rc-progress';
import 'rc-progress/assets/index.css';
import theme from 'styles/theme';
import styles from './Line.module.scss';

const LineProgressBar = ({ percent }) => {
  return (
    <div className={styles.progressContainer}>
      <Line percent={percent} strokeWidth="4" strokeColor={theme.primaryColor} />
    </div>
  );
};

export default LineProgressBar;
