import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import TokenManager from 'modules/token-manager';
import { ME_QUERY } from 'hooks/useUser';

const useLogout = () => {
  const client = useApolloClient();
  return React.useCallback(async () => {
    TokenManager.clearToken();
    client.writeQuery({ query: ME_QUERY, data: { me: null } });
    await client.resetStore();
  }, [client]);
};

export default useLogout;
