import React, { useCallback } from 'react';
import AppPage from 'components/layout/AppPage';
import ProfileCards from 'components/profile/ProfileCards';
import { useAlert } from 'components/common/Alert';
import Button from 'components/common/Button';
import TabTitle from 'components/common/TabTitle';
import styles from './Profile.module.scss';

const Profile = () => {
  const { showAlert } = useAlert();
  const handleDeleteAccount = useCallback(
    () =>
      showAlert({
        title: 'Are you sure you want to delete your account?',
        message: 'Will get back to you very soon with the results.',
        actions: [
          {
            title: 'Delete my account',
            onClick: () => console.log('account was deleted'),
          },
        ],
      }),
    [showAlert],
  );
  return (
    <AppPage>
      <div className={styles.header}>
        <TabTitle>My Account</TabTitle>
        <div className={styles.deleteButton}>
          <Button color="transparent" type="button" onClick={handleDeleteAccount}>
            Delete my account
          </Button>
        </div>
      </div>
      <div style={{ marginLeft: '35px' }}>
        <ProfileCards />
      </div>
      <TabTitle>Invoices</TabTitle>
    </AppPage>
  );
};

export default Profile;
