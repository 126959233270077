import React from 'react';
import AuthPage from 'components/layout/AuthPage';
import AuthForm from 'components/auth/AuthForm/AuthForm';
import useSignUp from 'hooks/auth/useSignUp';
import useAuthFormSubmit from 'hooks/auth/useAuthFormSubmit';
import SecondaryContent from './SecondaryContent';

const SignUp = () => {
  const signUp = useSignUp();
  const handleSubmit = useAuthFormSubmit(signUp, true);

  return (
    <AuthPage secondaryContent={<SecondaryContent />}>
      <AuthForm isLogin={false} onSubmit={handleSubmit} />
    </AuthPage>
  );
};

export default SignUp;
