import { useState, useCallback } from 'react';

const useLoadingData = ({ key, length, fetchMore, variables }) => {
  const [hasMoreData, setHasMoreData] = useState(true);
  const fetchMoreData = useCallback(async () => {
    await fetchMore({
      variables: {
        ...variables,
        offset: length,
        limit: 5,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (fetchMoreResult[key].length === 0) {
          setHasMoreData(false);
          return prev;
        }
        setHasMoreData(true);
        return { ...prev, [key]: [...prev[key], ...fetchMoreResult[key]] };
      },
    });
  }, [fetchMore, variables, length, key]);

  return { hasMoreData, fetchMoreData };
};

export default useLoadingData;
