/* eslint-disable no-plusplus */
import React, { useState, useCallback } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Button from 'components/common/Button';
import Range from 'components/common/Range';
import styles from '../PersonalForm.module.scss';

const ImageCropper = ({ imageProps: { file }, handleUploadAvatar }) => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    image: 'avatar.jpg',
    allowZoomOut: false,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotate: 0,
    borderRadius: 0,
    editor: null,
    width: 512,
    height: 512,
  });

  const dataURLtoFile = useCallback((dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }, []);

  const setEditorRef = useCallback((editor) => {
    if (editor) {
      setState((s) => ({ ...s, editor }));
    }
  }, []);

  const handleCrop = useCallback(async () => {
    const { editor } = state;
    setLoading(true);

    if (editor !== null) {
      const url = editor.getImageScaledToCanvas().toDataURL();

      setState((s) => ({ ...s, image: url }));
      const newImage = dataURLtoFile(url, 'newImage');

      await handleUploadAvatar(newImage);
    }
    setLoading(false);
  }, [state, dataURLtoFile, handleUploadAvatar]);

  const handleScale = useCallback((e) => {
    const scale = parseFloat(e.target.value);
    setState((s) => ({ ...s, scale }));
  }, []);

  const handlePositionChange = useCallback((position) => {
    setState((s) => ({ ...s, position }));
  }, []);

  return (
    <div>
      <AvatarEditor
        disableHiDPIScaling
        image={file || ' '}
        scale={parseFloat(state.scale)}
        ref={setEditorRef}
        width={state.width}
        height={state.height}
        position={state.position}
        onPositionChange={handlePositionChange}
        rotate={parseFloat(state.rotate)}
        borderRadius={state.width / (100 / state.borderRadius)}
      />
      <Range
        name="scale"
        label="Zoom"
        onChange={handleScale}
        min={state.allowZoomOut ? '0.1' : '1'}
        max="2"
        step="0.01"
      />
      <Button onClick={handleCrop} loading={loading} className={styles.buttonCropImage}>
        Upload
      </Button>
    </div>
  );
};

export default ImageCropper;
