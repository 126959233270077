import React from 'react';
import AppPage from 'components/layout/AppPage';
import Controls from 'components/campaign/Controls/Controls';
import CampaignStats from 'components/campaign/CampaignsStats';
import StatusHistory from 'components/campaign/StatusHistory';

const EmailCampaign = () => {
  const handleClickeAddNewCampaign = () => {
    console.log('added a new campaign');
  };
  const handleClickTryOutTutorials = () => {
    console.log('Clicked on try out tutorials');
  };

  return (
    <AppPage>
      <Controls onClickAddNewCampaign={handleClickeAddNewCampaign} rights />
      <CampaignStats />
      <StatusHistory onClickTryOutTutorials={handleClickTryOutTutorials} />
    </AppPage>
  );
};

export default EmailCampaign;
