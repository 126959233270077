import React from 'react';
import Loading from 'components/common/Loading';
import AppRouter from './AppRouter';
import AuthRouter from './AuthRouter';
import useUser from '../hooks/useUser';

const RootRouter = () => {
  const [user, loading] = useUser();

  if (loading && !user) {
    return <Loading overlay active />;
  }

  return <>{user ? <AppRouter /> : <AuthRouter />}</>;
};

export default RootRouter;
