import React from 'react';
import InputLabel from 'components/form/Label';
import styles from './Range.module.scss';

const Range = ({ label, ...props }) => {
  return (
    <div className={styles.range}>
      <InputLabel>{label}</InputLabel>
      <input {...props} type="range" defaultValue="1" />
    </div>
  );
};

export default Range;
