import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthLayout from '../components/layouts/AuthLayout';
import routes from './routes';
import routePaths from './route-paths';

const authRoutes = routes.filter(({ group }) => group === 'auth');
const withLayout = (C) => (props) => (
  <AuthLayout {...props}>
    <C {...props} />
  </AuthLayout>
);

const AuthRouter = () => (
  <Switch>
    <Redirect from={routePaths.home} to={routePaths.login} exact />
    {authRoutes.map(({ path, component: C, wrapLayout }) => (
      <Route path={path} exact component={wrapLayout === false ? C : withLayout(C)} key={path} />
    ))}
    <Route render={() => <Redirect to={routePaths.login} exact />} />
  </Switch>
);

export default AuthRouter;
