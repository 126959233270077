import { useCallback } from 'react';
import gql from 'graphql-tag';
import useAuth from 'hooks/auth/useAuth';
import useUser, { CURRENT_USER_FRAGMENT } from 'hooks/useUser';
import { toast } from 'react-toastify';

const REDIRECT_URI = `${window.location.origin}${process.env.REACT_APP_OAUTH_REDIRECT_URI}`;
const SCOPE = 'r_emailaddress r_liteprofile';

const randomString = () => parseInt(Math.random() * 100000000, 10).toString();

const windowSettings = ['width=600', 'height=700'];

const AUTH_WITH_LINKED_IN = gql`
  mutation authWithLinkedIn($token: String!, $redirectUri: String!) {
    authWithLinkedIn(token: $token, redirectUri: $redirectUri) {
      token
      user {
        _id
        ...currentUser
      }
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

const useLinkedInAuth = () => {
  const params = {
    response_type: 'code',
    client_id: process.env.REACT_APP_LINKED_IN_CLIENT_ID,
    redirect_uri: REDIRECT_URI,
    state: randomString(), // String that will ensure that we have response from our request and not from hackers
    scope: SCOPE,
  };
  const [me] = useUser();
  const mutationParams = me ? { refetchQueries: ['me'] } : {};

  const [auth, loading] = useAuth(AUTH_WITH_LINKED_IN, mutationParams);

  // URL for popup window
  const searchParams = new URLSearchParams(params);
  const authorizationURL = `https://www.linkedin.com/oauth/v2/authorization?${searchParams}`;

  // authorization code request
  const receiveCode = useCallback(
    async ({ code, state }) => {
      try {
        if (state !== params.state) return null;
        window.oauth = null;
        await auth({ token: code, redirectUri: REDIRECT_URI });
        if (me) toast.success('Service was successfully connected');
      } catch (e) {
        toast.error(e.message);
      }
    },
    [auth, me, params.state],
  );

  const login = useCallback(async () => {
    window.open(authorizationURL, '', windowSettings.join(','));
    window.oauth = receiveCode;
  }, [authorizationURL, receiveCode]);

  return [login, loading];
};

export default useLinkedInAuth;
