import React from 'react';
import AuthPage from 'components/layout/AuthPage';
import AuthForm from 'components/auth/AuthForm/AuthForm';
import useLogin from 'hooks/auth/useLogin';
import useAuthFormSubmit from 'hooks/auth/useAuthFormSubmit';
import SecondaryContent from './SecondaryContent';

const Login = () => {
  const login = useLogin();
  const handleSubmit = useAuthFormSubmit(login);

  return (
    <AuthPage secondaryContent={<SecondaryContent isLogin />}>
      <AuthForm isLogin onSubmit={handleSubmit} />
    </AuthPage>
  );
};

export default Login;
