import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const CURRENT_USER_FRAGMENT = gql`
  fragment currentUser on User {
    _id
    fullName
    country
    email
    businessEmail
    phone {
      code
      number
    }
    timeZone
    consent
    avatar
    createdAt
    emailVerified
    subscription {
      product
      period
      status
      endDate
    }
    bundle {
      counters {
        businesses
        requests
        campaigns
      }
    }
    services
  }
`;

export const ME_QUERY = gql`
  query me {
    me {
      ...currentUser
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

const useUser = () => {
  const { loading, error, data } = useQuery(ME_QUERY);
  return [data?.me, loading, error];
};

export default useUser;
