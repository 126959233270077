import React from 'react';

const Title = ({ title }) => {
  return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{title}</div>;
};

const tabs = [
  {
    id: 1,
    title: <Title title="Email" />,
    type: 'email',
  },
  {
    id: 2,
    title: <Title title="Location" />,
    type: 'facebook',
  },
  {
    id: 3,
    title: <Title title="Category" />,
    type: 'linked_in',
  },
];

export default tabs;
