import { useEffect } from 'react';

const OAuthCallbackPage = () => {
  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    const code = params.get('code');
    const state = params.get('state');
    if (window.opener && window.opener.oauth) {
      window.opener.oauth({ code, state });
    }
    window.close();
  }, []);
  return null;
};

export default OAuthCallbackPage;
