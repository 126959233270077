import React from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import PlayIcon from './PlayIcon';
import styles from './Videos.module.scss';
import TabTitle from '../common/TabTitle';
import Button from '../common/Button';

const validImageSizes = ['default', 'hqdefault', 'mqdefault', 'sddefault', 'maxresdefault'];

const getThumbnailVariant = (imageSize) => (validImageSizes.includes(imageSize) ? imageSize : 'default');

const getThumbnailUrl = (id, quality) => `https://img.youtube.com/vi/${id}/${getThumbnailVariant(quality)}.jpg`;

const Videos = ({ thumbnailQuality, videos }) => {
  const [openedVideo, setOpenedVideo] = React.useState(null);

  return (
    <>
      <TabTitle>Tutorials</TabTitle>
      <div className={styles.videoList}>
        {videos.map((video) => (
          <div key={video.id} className={styles.videoItem}>
            <Button type="button" className={styles.videoItem__button} onClick={() => setOpenedVideo(video.id)}>
              <img className={styles.imgTutorial} src={getThumbnailUrl(video.id, thumbnailQuality)} alt="Video" />
              <PlayIcon />
            </Button>
            <div className={styles.videoItem__info}>
              <div className={styles.infoTitle}>{video.title}</div>
              <div className={styles.infoDescription}>{video.description}</div>
            </div>
            <ModalVideo
              channel="youtube"
              autoplay={1}
              isOpen={openedVideo === video.id}
              videoId={video.id}
              onClose={() => setOpenedVideo(null)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default Videos;
