import React from 'react';
import Videos from 'components/tutorials/Videos';
import AppPage from 'components/layout/AppPage';
import videos from '../videoTutorials.json';

const Tutorials = () => {
  return (
    <AppPage>
      <Videos videos={videos} thumbnailQuality="mqdefault" />
    </AppPage>
  );
};

export default Tutorials;
