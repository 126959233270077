import React from 'react';
import * as yup from 'yup';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Field, Form } from 'formik';
import Button from 'components/common/Button';
import FormFeedback from 'components/form/FormFeedback';
import Card from 'components/common/Card';
import TextInput from 'components/form/formik/TextInput';
import routePaths from 'router/route-paths';
import { Link } from 'react-router-dom';
import InputLabel from 'components/form/Label';
import styles from './SetNameForm.module.scss';

const setNamePasswordShape = {
  name: yup.string().required('Name is required.'),
};

const setNameParamsSchema = yup.object().shape(setNamePasswordShape);

const initialValue = { name: '' };

const SetNameForm = (onSubmit) => {
  return (
    <div className={styles.container}>
      <div className={styles.cardContainer}>
        <Card customStyle={styles.card}>
          <div className={styles.title}>Success</div>
          <div className={styles.message}>
            Your email address (danielshnaider@gmail.com) Was successfully connected to BOOST
          </div>
        </Card>
        <Card customStyle={styles.card}>
          <Formik initialValues={initialValue} validationSchema={setNameParamsSchema} onSubmit={onSubmit}>
            {({ isSubmitting, errors }) => (
              <div>
                <div className={styles.title}>Set you name</div>
                <Form className={styles.form}>
                  <Field type="text" name="name" component={TextInput} label="From name" />
                  <FormFeedback valid={false} style={{ display: 'block' }}>
                    {errors.form}
                  </FormFeedback>
                  <Button
                    className={styles.saveButton}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    color="secondary"
                    type="submit"
                  >
                    Save
                    <FontAwesomeIcon icon={faSave} className={styles.buttonIconStyle} />
                  </Button>
                </Form>
              </div>
            )}
          </Formik>
        </Card>
      </div>
      <div className={styles.footerContainer}>
        <InputLabel>
          <Link to={routePaths.home}>Disconnect</Link>
        </InputLabel>
        <Button className={styles.nextButton} color="primary" onClick={() => {}}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default SetNameForm;
