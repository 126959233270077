import React from 'react';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import routePaths from 'router/route-paths';

const useAuthFormSubmit = (auth, isSignUp) => {
  const history = useHistory();
  return React.useCallback(
    async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      try {
        const data = await auth(values);
        const emailVerified = get(data, 'emailVerified', true);
        if (emailVerified && !isSignUp) {
          history.push(routePaths.home);
        }
      } catch (error) {
        setErrors({ form: error.message });
        setSubmitting(false);
      }
    },
    [auth, history, isSignUp],
  );
};

export default useAuthFormSubmit;
