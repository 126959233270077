import { useCallback } from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import { TEMPLATE_FRAGMENT } from './useTemplates';

const UPSERT_TEMPLATE = gql`
  mutation upsertTemplate($template: TemplateInput!, $_id: ID) {
    upsertTemplate(template: $template, _id: $_id) {
      ...template
    }
  }
  ${TEMPLATE_FRAGMENT}
`;

const useUpsertTemplate = (type, _id, onSuccess = () => {}) => {
  const [upsertTemplate] = useMutation(UPSERT_TEMPLATE, {
    onCompleted: () => {
      onSuccess();
      toast.success(`Template successfully ${_id ? 'updated' : 'created'}`);
    },
    refetchQueries: ['templates'],
  });

  const handleUpsert = useCallback(
    async (values) => {
      return upsertTemplate({ variables: { template: { type, ...values }, _id } });
    },
    [_id, type, upsertTemplate],
  );
  return handleUpsert;
};

export default useUpsertTemplate;
