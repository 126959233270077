import React from 'react';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

const useQueryParams = () => {
  const { search } = useLocation();
  return React.useMemo(() => {
    return qs.parse(search, { ignoreQueryPrefix: true });
  }, [search]);
};

export default useQueryParams;
