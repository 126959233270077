import { useState, useCallback } from 'react';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { useApolloClient } from 'react-apollo';

const GET_COUNT = gql`
  query($country: String!, $city: String!, $category: String!) {
    availableBusinessesCount(country: $country, city: $city, category: $category)
  }
`;

const useAvailableBusinessCount = (setSearchParams) => {
  const [count, setCount] = useState(null);
  const client = useApolloClient();

  const getAvailableBusinessCount = useCallback(
    async ({ country, city, category }) => {
      const { data } = await client.query({
        query: GET_COUNT,
        variables: { country, city, category },
        skip: !country || !city || !category,
      });
      const result = get(data, 'availableBusinessesCount', null);
      setCount(result);
      setSearchParams({ country, city, category });
      return count;
    },
    [client, count, setSearchParams],
  );

  return [count, getAvailableBusinessCount];
};

export default useAvailableBusinessCount;
