/* eslint-disable no-param-reassign */
import React, { useMemo } from 'react';
import pluralize from 'pluralize';
import { TextInput } from 'components/form/TextInput/TextInput';
import Button from 'components/common/Button';
import styles from './ListItemInfo.module.scss';

const SearchBar = (props) => {
  const { businessUnits, handleChange, handleCSVExport, handleXlsxExport } = props;
  const businessesCount = businessUnits.length;
  const emailsCount = useMemo(
    () =>
      businessUnits.reduce((cur, business) => {
        if (business.contacts) {
          cur += business.contacts.length;
        }
        return cur;
      }, 0),
    [businessUnits],
  );

  return (
    <div className={styles.searchBar}>
      <div className={styles.totalsInfo}>
        <div>Total</div>
        <div className={styles.businessesCount}>
          <span>{businessesCount}</span> {pluralize('Business', businessesCount)}
        </div>
        <div className={styles.emailsCount}>
          <span>{emailsCount}</span> Email {pluralize('Addresses', emailsCount)}
        </div>
      </div>
      <div className={styles.searchInput}>
        <TextInput name="search" placeholder="Search" onChange={handleChange} />
      </div>
      <div className={styles.actions}>
        <Button className={styles.csvButton} onClick={handleCSVExport}>
          Download CSV
        </Button>
        <Button className={styles.exelButton} onClick={handleXlsxExport}>
          Download Excel
        </Button>
      </div>
    </div>
  );
};

export default SearchBar;
