import React, { useCallback } from 'react';
import throttle from 'lodash/throttle';
import AppPage from 'components/layout/AppPage';
import useMyLists from 'hooks/useMyLists';
import List from 'components/list/List';
import Modal, { useModal } from 'components/common/Modal';
import Loading from 'components/common/Loading';
import NewList from 'components/list/NewList';
import useLoadingData from 'hooks/useLoadingData';
import useUpgradeReminder from 'hooks/useUpgradeReminder';

const MyLists = () => {
  const { lists, loading, fetchMore } = useMyLists();
  const { showModal, hideModal, isVisible } = useModal();
  const checkCounts = useUpgradeReminder();

  const handleListFormModal = useCallback(() => {
    if (checkCounts(['businesses', 'requests'])) showModal();
  }, [checkCounts, showModal]);

  const throttledFetchMore = useCallback(throttle(fetchMore, 200), []);
  const { hasMoreData, fetchMoreData } = useLoadingData({
    key: 'lists',
    length: lists.length,
    fetchMore: throttledFetchMore,
  });

  if (loading && lists.length === 0) return <Loading />;

  return (
    <AppPage>
      <Modal visible={isVisible} close={hideModal}>
        <NewList closeForm={hideModal} />
      </Modal>
      <List
        data={lists}
        hasMoreData={hasMoreData}
        fetchMoreListItems={fetchMoreData}
        openListFormModal={handleListFormModal}
      />
    </AppPage>
  );
};

export default MyLists;
