import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import useForgotPassword from 'hooks/auth/useForgotPassword';
import useUser from 'hooks/useUser';
import ChangePasswordForm from './ChangePasswordForm';
import useChangePassword from './useChangePassword';

const useChangePasswordSubmitHandler = () => {
  const changePassword = useChangePassword();
  const [response, setResponse] = useState({
    isError: false,
    message: '',
  });

  const handleSubmit = useCallback(
    async (variables) => {
      try {
        await changePassword(variables);
        setResponse({ isError: false, message: 'Changes saved successfully' });
      } catch (error) {
        setResponse({ isError: true, message: error.message });
      }
    },
    [changePassword],
  );

  return { handleSubmit, response };
};

const useForgotPasswordHandler = () => {
  const [me] = useUser();
  const { email } = me;
  const forgotPassword = useForgotPassword();

  const handleSubmit = useCallback(async () => {
    try {
      await forgotPassword({ email });
      toast.success('Go to your inbox and click the activation link to reset your Boost account password.');
    } catch (error) {
      toast.error(error.message);
    }
  }, [email, forgotPassword]);

  return handleSubmit;
};

const ChangePasswordTab = () => {
  const onClickForgotPassword = useForgotPasswordHandler();
  const { handleSubmit, response } = useChangePasswordSubmitHandler();

  return (
    <ChangePasswordForm onSubmit={handleSubmit} onClickForgotPassword={onClickForgotPassword} response={response} />
  );
};

export default ChangePasswordTab;
