import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

export const SEND_REPORT = gql`
  mutation sendReport($businessId: ID!, $type: EntityType!, $reason: String!, $message: String!) {
    sendReport(businessId: $businessId, type: $type, reason: $reason, message: $message)
  }
`;

const useSendReport = (type, businessId) => {
  const [sendReportMutation] = useMutation(SEND_REPORT);
  const sendReport = useCallback(
    async (variables) => {
      try {
        await sendReportMutation({ variables: { type, businessId, ...variables } });
        return;
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error;
        throw e;
      }
    },
    [businessId, sendReportMutation, type],
  );
  return sendReport;
};

export default useSendReport;
