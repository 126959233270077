import React, { useState } from 'react';
import { faPencilAlt, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import useUser from 'hooks/useUser';
import { useUpgradePlanHandler } from 'hooks/useSubscribe';
import useUserSubscription from 'hooks/useUserSubscription';
import PricingPlans from 'components/subscribe/PricingPlans';
import PresentationCard from '../common/PresentationCard/PresentationCard';
import StatisticsCard from '../common/StatisticsCard/StatisticsCard';
import Modal, { useModal } from '../common/Modal';
import ProfileTabs from './tabs/ProfileTabs';
import styles from './ProfileCards.module.scss';

const getFormattedDate = (date) => moment(`${date}`, 'YYYY-MM-DD').format('MMM DD YYYY');

const ProfileCardInfo = ({ fullName, email }) => {
  return (
    <div className={styles.profileCard__wrapper}>
      <div className={styles.profileCard__fullName}>{fullName}</div>
      <div className={styles.profileCard__email}>{email}</div>
    </div>
  );
};

const CurrentPlanInfo = ({ plan }) => <div className={styles.currentPlan__info}>{plan}</div>;

const ProfileCards = () => {
  const [me] = useUser();
  const handleUpgrade = useUpgradePlanHandler();
  const {
    plan,
    product,
    period,
    renewalDate,
    businessesUsed,
    businessesTotal,
    requestsUsed,
    requestsTotal,
  } = useUserSubscription();
  const { showModal, isVisible, hideModal } = useModal();
  const { fullName, createdAt, email, avatar } = me;
  const userAvatar = avatar || require('assets/img/user.svg');
  const [modal, setModal] = useState('');

  return (
    <div className={styles.wrapper}>
      <PresentationCard
        button={{
          icon: faPencilAlt,
          onClick: () => {
            setModal('profile');
            showModal();
          },
        }}
        info={<ProfileCardInfo fullName={fullName} email={email} />}
        image={{ src: userAvatar, alt: 'avatar' }}
        footer={`Member since: ${getFormattedDate(createdAt)}`}
      />
      <PresentationCard
        title="Current Plan"
        button={{
          icon: faArrowUp,
          onClick: () => {
            setModal('plan');
            showModal();
          },
        }}
        info={<CurrentPlanInfo plan={plan} />}
        image={{
          src: 'https://www.powerformula.net/wp-content/uploads/2019/12/iStock-1187265879-300x200.jpg',
          alt: 'current plan',
        }}
        footer={`Next renewal on ${renewalDate} `}
      />
      <div>
        <div>
          <StatisticsCard
            title="Usage"
            info={{ amount: { current: businessesUsed, common: businessesTotal }, description: 'Businesses' }}
          />
        </div>
        <div>
          <StatisticsCard
            info={{ amount: { current: requestsUsed, common: requestsTotal }, description: 'Requests' }}
          />
        </div>
      </div>
      <Modal visible={isVisible} close={hideModal}>
        {modal === 'profile' ? (
          <ProfileTabs />
        ) : (
          <PricingPlans upgradePlan={handleUpgrade} product={product} period={period} />
        )}
      </Modal>
    </div>
  );
};

export default ProfileCards;
