import React from 'react';
import * as yup from 'yup';
import { Formik, Field, Form } from 'formik';
// import AuthInput from 'components/auth/AuthInput';
import Button from 'components/common/Button';
import FormFeedback from 'components/form/FormFeedback';
import { Link } from 'react-router-dom';
import routePaths from 'router/route-paths';
import TextInput from 'components/form/formik/TextInput';
import InputLabel from 'components/form/Label';
import styles from './ForgotPasswordForm.module.scss';

const forgotPasswordShape = {
  email: yup
    .string()
    .required('Email is required.')
    .email('Email is invalid.'),
};

const forgotPassParamsSchema = yup.object().shape(forgotPasswordShape);

const initialValue = { email: '' };

const ForgotPasswordForm = ({ onSubmit, existsEmail }) => {
  return (
    <div>
      <Formik initialValues={initialValue} validationSchema={forgotPassParamsSchema} onSubmit={onSubmit}>
        {({ isSubmitting, errors, touched }) => (
          <>
            <div>
              <div className={styles.title}>Forgot your password?</div>
              {!existsEmail && (
                <Form className={styles.form}>
                  <Field
                    type="email"
                    name="email"
                    component={TextInput}
                    invalid={!!(touched.email && errors.email)}
                    label="Email Address"
                  />
                  <div className={styles.footerForm}>
                    <InputLabel style={{ textDecoration: 'underline' }}>
                      <Link to={routePaths.home}>Terms & Conditions</Link>
                    </InputLabel>
                    <InputLabel style={{ textDecoration: 'underline' }}>
                      <Link to={routePaths.home}>Privacy Policy</Link>
                    </InputLabel>
                  </div>
                  <FormFeedback valid={false} style={{ display: 'block' }}>
                    {errors.form}
                  </FormFeedback>
                  <div className={styles.buttonContainer}>
                    <Button
                      className={styles.button}
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      color="secondary"
                      type="submit"
                    >
                      Reset password
                    </Button>
                  </div>
                </Form>
              )}
              {existsEmail && (
                <div>
                  <p>Go to your inbox and click the activation link to reset your Boost account password.</p>
                </div>
              )}
              {existsEmail && (
                <Button color="secondary" as={Link} to={routePaths.login}>
                  Back to login
                </Button>
              )}
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPasswordForm;
