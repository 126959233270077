import React from 'react';
import { Link } from 'react-router-dom';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/common/Button';
import CircleButton from 'components/common/Circle-Button';
import routePaths from 'router/route-paths';
import styles from './Controls.module.scss';

const Controls = ({ rights, onClickAddNewCampaign }) => {
  return (
    <div className={styles.containerHeader}>
      <Button onClick={onClickAddNewCampaign} color="inverted" className={styles.btnNewCampaign}>
        Start a new campaign
      </Button>
      {rights ? (
        <div className={styles.loginWrapper}>
          <div>
            <span className={styles.message}>Signed in as: </span>
            <span className={styles.label}>danielshnaider@gmail.com</span>
          </div>
          <CircleButton icon={faSignOutAlt} color="primary" size="sm" />
        </div>
      ) : (
        <div className={styles.loginWrapper}>
          <span className={styles.message}>You are not loged in: </span>
          <Link className={styles.label} style={{ textDecoration: 'underline' }} to={routePaths.campaignPage}>
            Login to email account
          </Link>
        </div>
      )}
    </div>
  );
};

export default Controls;
