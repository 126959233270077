import React, { useRef, useCallback } from 'react';
import ReactQuill from 'react-quill';
import './Editor.scss';

const Editor = ({ field }) => {
  const { value, name, onChange } = field;
  const editorRef = useRef();

  const undoHistory = useCallback(() => editorRef.current.getEditor().history.undo(), []);
  const redoHistory = useCallback(() => editorRef.current.getEditor().history.redo(), []);

  const modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline'],
        [{ font: [] }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['undo', 'redo'],
      ],
      handlers: {
        undo: undoHistory,
        redo: redoHistory,
      },
    },
  };
  return (
    <div className="editor-container">
      <ReactQuill
        ref={editorRef}
        theme="snow"
        value={value}
        onChange={onChange(name)}
        placeholder="Type in your message or copy and paste from notepad. 
    Please avoid copying from the WORD doc or website."
        modules={modules}
      />
    </div>
  );
};

export default Editor;
