import React from 'react';
import { Circle } from 'rc-progress';
import 'rc-progress/assets/index.css';
import theme from 'styles/theme';
import styles from './Circle.module.scss';

const CircleProgressBar = ({ amount }) => {
  const { current, common } = amount;
  const filledArea = (current / common) * 100;
  return (
    <div className={styles.progressContainer}>
      <Circle
        className={styles.circle}
        percent={filledArea}
        strokeWidth="10"
        trailWidth="8"
        trailColor="#f5f5f5"
        strokeColor={theme.primaryColor}
      />
      <span className={styles.data}>{`${current}/${common}`}</span>
    </div>
  );
};

export default CircleProgressBar;
