import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import classnames from 'classnames';
import PersonalTab from './personal/PersonalTab';
import ContactTab from './contact/ContactTab';
import ChangePasswordTab from './password/ChangePasswordTab';
import styles from './ProfileTabs.module.scss';
import 'react-tabs/style/react-tabs.css';

const tabs = [
  {
    title: 'Personal',
    Component: PersonalTab,
  },
  {
    title: 'Contact',
    Component: ContactTab,
  },
  {
    title: 'Password',
    Component: ChangePasswordTab,
  },
];

const ProfileTabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Tabs selectedIndex={activeTab} selectedTabClassName={styles.isSelected} onSelect={setActiveTab}>
      <TabList className={styles.tabList}>
        {tabs.map(({ title }) => (
          <Tab key={title} className={classnames('react-tabs__tab', styles.tabList__item)}>
            {title}
          </Tab>
        ))}
      </TabList>
      {tabs.map(({ Component, title }) => (
        <TabPanel key={title}>
          <Component />
        </TabPanel>
      ))}
    </Tabs>
  );
};

export default ProfileTabs;
