import React from 'react';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TabTitle from 'components/common/TabTitle';
import Button from 'components/common/Button';
import styles from './StatusHistory.module.scss';

const StatusHistory = ({ onClickTryOutTutorials }) => {
  return (
    <div className={styles.historyWrapper}>
      <TabTitle>Status & History</TabTitle>
      <div className={styles.tutorials}>
        <div className={styles.message}>Want to improve your skills?</div>
        <Button onClick={onClickTryOutTutorials} color="inverted" className={styles.btnTutorials}>
          Try our tutorials
          <FontAwesomeIcon icon={faArrowRight} className={styles.btnIcon} />
        </Button>
      </div>
    </div>
  );
};

export default StatusHistory;
