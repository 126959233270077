import React, { useMemo, useCallback } from 'react';
import moment from 'moment';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import styles from 'components/list/ListItem.module.scss';
import CircleButton from 'components/common/Circle-Button';
import Card from 'components/common/Card';

const getFormattedDate = (date) => moment(`${date}`, 'YYYY-MM-DD').format('MMM DD YYYY');

const ListItem = (props) => {
  const {
    id: listId,
    category,
    location: { city, country },
    businessesCount,
    createdAt,
    openListItemModal,
    getFlag,
  } = props;

  const date = useMemo(() => getFormattedDate(createdAt), [createdAt]);
  const handleClick = useCallback(() => openListItemModal({ listId }), [listId, openListItemModal]);

  return (
    <Card>
      <div className={styles.container__item}>
        <div>{getFlag(country)}</div>
        <div className={styles.fieldValue}>{country}</div>
      </div>
      <div className={styles.container__item}>
        <div className={styles.fieldName}>City/State</div>
        <div className={styles.fieldValue}>{city}</div>
      </div>
      <div className={styles.container__item}>
        <div className={styles.fieldName}>Category</div>
        <div className={styles.fieldValue}>{category}</div>
      </div>
      <div className={styles.container__item}>
        <div className={styles.fieldName}>Quantity</div>
        <div className={styles.fieldValue}>{`${businessesCount} businesses`}</div>
      </div>
      <div className={styles.container__item}>{date}</div>
      <CircleButton icon={faEye} color="primary" size="md" onClick={handleClick} />
    </Card>
  );
};

export default ListItem;
