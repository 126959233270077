/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import * as yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/common/Button';
import FormFeedback from 'components/form/FormFeedback';
import InputLabel from 'components/form/Label/Label';
import { countries } from 'countries-list';
import FormSelect from 'components/form/formik/Select';
import { map } from 'lodash';
import TextInput from 'components/form/formik/TextInput';
import theme from 'styles/theme';
import CircleButton from 'components/common/Circle-Button';
import socialButtons from './SocialButtons';
import styles from './ContactForm.module.scss';
import PhoneNumberInput from './PhoneNumberInput';

const phonesList = map(countries, (object) => ({
  label: object.emoji.concat('+', object.phone),
  value: object.phone,
}));

const contactShape = {
  businessEmail: yup
    .string()
    .email('Email is invalid.')
    .nullable(),
  code: yup.string().nullable(),
  number: yup.string().when('code', {
    is: (code) => code,
    then: yup
      .string()
      .required('Enter phone number')
      .min(9, 'Phone number should be at least 9 digits')
      .max(15, 'Phone number should be no more than 15 digits'),
  }),
};

const schema = yup.object().shape(contactShape);

const ContactForm = ({ onSubmit, initialValues, response, services, disconnectService, connectService }) => {
  return (
    <>
      <div>
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
          {({ isSubmitting, errors }) => (
            <>
              <div>
                <Form className={styles.form}>
                  <Field
                    type="email"
                    name="businessEmail"
                    component={TextInput}
                    placeholder="Enter you business email"
                    label="Business email"
                  />
                  <InputLabel>Phone number</InputLabel>
                  <div className={styles.form__phoneGroup}>
                    <Field
                      name="code"
                      component={FormSelect}
                      options={phonesList}
                      placeholder="Country"
                      className={styles.form__selectCode}
                    />
                    <Field
                      type="tel"
                      name="number"
                      pattern="[0-9]{9,15}"
                      component={PhoneNumberInput}
                      placeholder="Enter number"
                    />
                  </div>
                  <InputLabel>Social Networks</InputLabel>
                  <div className={styles.socialButtons}>
                    {socialButtons.map(({ id, name, icon, iconColor }) => (
                      <div key={name} className={styles.socialButtonContainer}>
                        <CircleButton
                          id={id}
                          className={services.includes(name) ? styles.socialButton : styles.mutedSocialButton}
                          icon={icon}
                          size="md"
                          color="secondary"
                          iconColor={iconColor}
                          onClick={services.includes(name) ? () => disconnectService(name) : () => connectService(name)}
                        />
                        {services.includes(name) ? (
                          <div>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color={theme.successColor}
                              className={styles.connectIcon}
                            />
                            <div className={styles.disconnectLabel}>Disconnect</div>
                          </div>
                        ) : (
                          <div className={styles.connectLabel}>Connect</div>
                        )}
                      </div>
                    ))}
                  </div>
                  <FormFeedback valid={false} style={{ display: 'block' }}>
                    {errors.form}
                  </FormFeedback>
                  <div className={styles.form__button}>
                    <Button disabled={isSubmitting} loading={isSubmitting} color="secondary" type="submit">
                      Save changes
                    </Button>
                  </div>
                  {response && (
                    <div className={response.isError ? styles.wrongResponse : styles.successResponse}>
                      {response.message}
                    </div>
                  )}
                </Form>
              </div>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ContactForm;
