import React, { useCallback } from 'react';
import AppPage from 'components/layout/AppPage';
import SupportForm from 'components/common/supportForm/SupportForm';
import { useAlert } from 'components/common/Alert';
import useContactSupport from 'hooks/useContactSupport';

const useContactSupportHandler = () => {
  const { showAlert } = useAlert();

  const contactSupport = useContactSupport();

  const handleSubmit = useCallback(
    async (variables, { resetForm }) => {
      try {
        await contactSupport(variables);
        resetForm({});
        showAlert({ title: 'Your message has been sent successfully', image: require('assets/img/subscribe.svg') });
      } catch (error) {
        showAlert({ title: error.message });
      }
    },
    [contactSupport, showAlert],
  );

  return handleSubmit;
};

const Support = () => {
  const handleSubmit = useContactSupportHandler();

  return (
    <AppPage>
      <SupportForm onSubmit={handleSubmit} />
    </AppPage>
  );
};

export default Support;
