import React from 'react';
import styles from './StatisticsCard.module.scss';
import CircleProgressBar from '../ProgressBar/Cicle';

const StatisticsCard = ({ title, info }) => {
  return (
    <div className={styles.card}>
      {title && <div className={styles.cardHeader}>{title}</div>}
      <div className={styles.statisticInfo}>
        <CircleProgressBar amount={info.amount} />
        <div className={styles.description}>{info.description}</div>
      </div>
    </div>
  );
};

export default StatisticsCard;
