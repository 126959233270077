import React from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { Formik, Field, Form } from 'formik';
// import AuthInput from 'components/auth/AuthInput';
import Button from 'components/common/Button';
import routePaths from 'router/route-paths';
import FormFeedback from 'components/form/FormFeedback';
import { faGoogle, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import CircleButton from 'components/common/Circle-Button';
import theme from 'styles/theme';
import Recaptcha from 'components/form/formik/Recaptcha';
import Checkbox from 'components/form/formik/Checkbox';
import TextInput from 'components/form/formik/TextInput';
import InputLabel from 'components/form/Label';
import useLinkedInAuth from 'hooks/auth/useLinkedInAuth';
import useGoogleAuthButton from 'hooks/auth/useGoogleAuthButton';
import useFacebookAuth from 'hooks/auth/useFacebookAuth';
import PasswordInput from '../PasswordInput/PasswordInput';
import styles from './AuthForm.module.scss';
// reactstrap components

const loginShape = {
  email: yup
    .string()
    .required('Email is required.')
    .email('Email is invalid.'),
  password: yup
    .string()
    .required('Password is required.')
    .min(8, 'Password should be at least 8 characters.'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
};
const signUpShape = {
  fullName: yup.string().required('Full Name is required.'),
  consent: yup.bool().oneOf([true], 'You have to agree with our Terms and Conditions'),
  captcha: yup
    .mixed()
    .nullable()
    .required('Please verify that you are not a robot!'),
  ...loginShape,
};
const loginParamsSchema = yup.object().shape(loginShape);
const signUpParamsSchema = yup.object().shape(signUpShape);

const initialValues = {
  fullName: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  remember: false,
  consent: false,
  captcha: null,
};

const GOOGLE_BUTTON_ID = 'google-oauth-btn';

const reCaptchaRef = React.createRef();

const AuthForm = ({ isLogin, onSubmit }) => {
  const authLabel = isLogin ? 'Log in' : 'Sign up';
  const [authWithGoogle, googleLoading] = useGoogleAuthButton(GOOGLE_BUTTON_ID);
  const [authWithLinkedIn, linkedInLoading] = useLinkedInAuth();
  const [authWithFacebook] = useFacebookAuth();

  const schema = React.useMemo(() => (isLogin ? loginParamsSchema : signUpParamsSchema), [isLogin]);
  return (
    <>
      <div>
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
          {({ isSubmitting, errors, touched, values }) => (
            <>
              <div className={styles.container}>
                <div className={styles.title}>{authLabel} to Boost</div>
                <div className={styles.socialButtons}>
                  <CircleButton
                    id={GOOGLE_BUTTON_ID}
                    onClick={authWithGoogle}
                    className={styles.socialButton}
                    icon={faGoogle}
                    size="md"
                    color="light"
                  />
                  {/* <div id="google-oauth2-btn" className={styles.invisibleButton} /> */}
                  <CircleButton
                    className={styles.socialButton}
                    onClick={authWithFacebook}
                    icon={faFacebookF}
                    size="md"
                    color="light"
                    iconColor={theme.facebookIconColor}
                  />
                  <CircleButton
                    className={styles.socialButton}
                    onClick={authWithLinkedIn}
                    icon={faLinkedinIn}
                    size="md"
                    color="light"
                    iconColor={theme.linkedInIconColor}
                  />
                </div>
                <div className={styles.dividerContainer}>
                  <hr className={styles.divider} />
                  <span className={styles.text}>OR</span>
                  <hr className={styles.divider} />
                </div>
                <Form className={styles.form}>
                  {!isLogin ? <Field type="text" name="fullName" component={TextInput} label="Full Name" /> : null}
                  <Field
                    type="email"
                    name="email"
                    component={TextInput}
                    invalid={!!(touched.email && errors.email)}
                    label="Email"
                  />
                  <Field name="password" component={PasswordInput} label="Password" />
                  {!isLogin ? (
                    <Field name="passwordConfirmation" component={PasswordInput} label="Repeat password" />
                  ) : null}
                  {!isLogin && (
                    <Field
                      className={styles.recaptcha}
                      component={Recaptcha}
                      value=""
                      name="captcha"
                      ref={reCaptchaRef}
                    />
                  )}
                  {isLogin ? (
                    <div className={styles.formFooter}>
                      <Field component={Checkbox} name="remember" label="Remember me" labelInline />
                      <InputLabel style={{ textDecoration: 'underline' }}>
                        <Link to={routePaths.forgotPassword}>Forgot password?</Link>
                      </InputLabel>
                    </div>
                  ) : (
                    <div className={styles.formFooter}>
                      <Field
                        component={Checkbox}
                        value="consent"
                        name="consent"
                        hideError
                        label="By registering I confirm that I accept the Terms & Conditions and Privacy Policy "
                        labelInline
                      />
                      {Object.entries(errors).map(([key, error]) => {
                        if (touched[key] && key === 'consent') return <FormFeedback key={key}>{error}</FormFeedback>;
                        return null;
                      })}
                    </div>
                  )}
                  <FormFeedback valid={false} style={{ display: 'block' }}>
                    {errors.form}
                  </FormFeedback>
                  <div className={styles.buttonContainer}>
                    <Button
                      className={styles.button}
                      disabled={isSubmitting}
                      loading={isSubmitting || googleLoading || linkedInLoading}
                      color="secondary"
                      type="submit"
                    >
                      {authLabel}
                    </Button>
                  </div>
                </Form>
              </div>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AuthForm;
