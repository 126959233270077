/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import * as yup from 'yup';
import { Formik, Field, Form } from 'formik';
import Button from 'components/common/Button';
import FormFeedback from 'components/form/FormFeedback';
import TextInput from 'components/form/formik/TextInput';
import styles from './ChangePasswordForm.module.scss';

const passwordShape = {
  oldPassword: yup.string().required('Enter your current password'),
  newPassword: yup
    .string()
    .required('Password is required.')
    .min(8, 'Password should be at least 8 characters.'),
  passwordConfirmation: yup.string().oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
};

const schema = yup.object().shape(passwordShape);

const initialValues = {
  oldPassword: '',
  newPassword: '',
  passwordConfirmation: '',
};

const ChangePasswordForm = ({ onSubmit, onClickForgotPassword, response }) => {
  return (
    <>
      <div>
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
          {({ isSubmitting, errors }) => (
            <>
              <div>
                <Form className={styles.form}>
                  <Field
                    type="password"
                    name="oldPassword"
                    component={TextInput}
                    label="Current password"
                    placeholder="Enter current password"
                  />
                  <div className={styles.wrapperForgotPassword}>
                    <span className={styles.form__fieldName_forgotPassword} onClick={onClickForgotPassword}>
                      Forgot password?
                    </span>
                  </div>
                  <Field
                    type="password"
                    name="newPassword"
                    component={TextInput}
                    label="New password"
                    placeholder="Enter new password"
                  />
                  <Field
                    type="password"
                    name="passwordConfirmation"
                    component={TextInput}
                    label="Verify password"
                    placeholder="Repeat password"
                  />
                  <FormFeedback valid={false} style={{ display: 'block' }}>
                    {errors.form}
                  </FormFeedback>
                  <div className={styles.form__button}>
                    <Button disabled={isSubmitting} loading={isSubmitting} color="secondary" type="submit">
                      Save changes
                    </Button>
                  </div>
                  {response && (
                    <div className={response.isError ? styles.wrongResponse : styles.successResponse}>
                      {response.message}
                    </div>
                  )}
                </Form>
              </div>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ChangePasswordForm;
