import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import Loading from './common/Loading';

const InfiniteScrollComponent = ({ hasMoreData, fetchMoreData, children }) => {
  return (
    <InfiniteScroll loadMore={fetchMoreData} hasMore={hasMoreData} loader={<Loading size={30} />}>
      {children}
    </InfiniteScroll>
  );
};

export default InfiniteScrollComponent;
