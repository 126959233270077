import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

export const BUSINESS_REQUEST = gql`
  mutation($input: businessRequestInput!) {
    businessRequest(input: $input)
  }
`;

const useBusinessRequest = () => {
  const [businessRequestMutation] = useMutation(BUSINESS_REQUEST);

  const businessRequest = useCallback(
    async (variables) => {
      try {
        await businessRequestMutation({
          variables: { input: variables },
        });
        return;
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error;
        throw e;
      }
    },
    [businessRequestMutation],
  );

  return businessRequest;
};

export default useBusinessRequest;
