import Profile from 'pages/Profile';
import Login from 'pages/auth/Login';
import SignUp from 'pages/auth/SignUp';
import ForgotPassword from 'pages/auth/ForgotPassword';
import Tutorials from 'pages/Tutorials';
import ResetPassword from 'pages/auth/ResetPassword';
import MyLists from 'pages/MyLists';
import Support from 'pages/Support';
import Placeholder from 'pages/Placeholder';
import VerifyEmail from 'pages/VerifyEmail';
import Templates from 'pages/Templates';
import EmailConnect from 'pages/EmailConnect';
import CampaignSetName from 'pages/CampaignSetName';
import OAuthCallbackPage from 'pages/auth/OAuthCallbackPage';
import Statistics from 'pages/Statistics';
import EmailCampaign from 'pages/EmailCampaign';
import routePaths from './route-paths';

export default [
  {
    path: routePaths.myLists,
    name: 'My lists',
    icon: null,
    component: MyLists,
    group: 'app',
    sidebar: true,
  },
  {
    path: routePaths.templates,
    name: 'Templates',
    icon: null,
    component: Templates,
    group: 'app',
    sidebar: true,
  },
  {
    path: routePaths.campaign,
    name: 'Email Campaign',
    icon: null,
    component: EmailCampaign,
    group: 'app',
    sidebar: false,
  },
  {
    path: routePaths.campaignPage,
    name: 'Email Campaign',
    icon: null,
    component: EmailConnect,
    group: 'app',
    sidebar: true,
  },
  {
    path: routePaths.campaignSetName,
    name: 'Email Campaign set name',
    icon: null,
    component: CampaignSetName,
    group: 'app',
    sidebar: false,
  },
  {
    path: routePaths.statistics,
    name: 'Statistics',
    icon: null,
    component: Statistics,
    group: 'app',
    sidebar: true,
  },
  {
    path: routePaths.tutorials,
    name: 'Tutorials',
    icon: null,
    component: Tutorials,
    group: 'app',
    sidebar: true,
  },
  {
    path: routePaths.profile,
    name: 'My Account',
    icon: null,
    component: Profile,
    group: 'app',
    sidebar: true,
  },
  {
    path: routePaths.support,
    name: 'Support',
    icon: null,
    component: Support,
    group: 'app',
    sidebar: true,
  },
  {
    path: routePaths.verifyEmail,
    name: 'Verify email',
    icon: null,
    component: VerifyEmail,
    group: 'app',
  },
  {
    path: routePaths.login,
    name: 'Login',
    icon: null,
    component: Login,
    group: 'auth',
  },
  {
    path: routePaths.signUp,
    name: 'Sign up',
    icon: null,
    component: SignUp,
    group: 'auth',
  },
  {
    path: routePaths.forgotPassword,
    name: 'Forgot password',
    icon: null,
    component: ForgotPassword,
    group: 'auth',
  },
  {
    path: routePaths.resetPassword,
    name: 'Reset password',
    icon: null,
    component: ResetPassword,
    group: 'auth',
  },
  {
    path: routePaths.oAuthLogin,
    name: 'Third-party login',
    icon: null,
    component: OAuthCallbackPage,
    group: 'auth',
  },
  {
    path: routePaths.oAuthLogin,
    name: 'Third-party login',
    icon: null,
    component: OAuthCallbackPage,
    group: 'app',
  },
];
