import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

export const RESET_PASSWORD = gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password)
  }
`;

const useResetPassword = () => {
  const [resetPasswordMutation] = useMutation(RESET_PASSWORD);

  const resetPassword = useCallback(
    async ({ password, token }) => {
      try {
        await resetPasswordMutation({
          variables: { token, password },
        });
        return;
      } catch (error) {
        const e = error.graphQLErrors
          ? new Error(error.graphQLErrors.map((err) => err.message))
          : error;
        e.raw = error;
        throw e;
      }
    },
    [resetPasswordMutation],
  );

  return resetPassword;
};

export default useResetPassword;
