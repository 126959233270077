import React from 'react';
import { getEmojiFlag } from 'countries-list';
import ListItem from 'components/list/ListItem';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import CircleButton from 'components/common/Circle-Button';
import GhostCard from 'components/common/GhostCard';
import Modal, { useModal } from 'components/common/Modal';
import InfiniteScrollComponent from 'components/InfiniteScrollComponent';
import { useAlert } from 'components/common/Alert';
import ListItemInfo from 'components/list/ListItemInfo';
import styles from './List.module.scss';

const getFlag = (idCountry) => getEmojiFlag(idCountry);

const List = ({ data, hasMoreData, fetchMoreListItems, openListFormModal }) => {
  const { showAlert, hideAlert } = useAlert();
  const { showModal, modalProps, hideModal, isVisible } = useModal();
  return (
    <>
      <InfiniteScrollComponent hasMoreData={hasMoreData} fetchMoreData={fetchMoreListItems}>
        <div className={styles.list}>
          <GhostCard onClick={openListFormModal} customStyle={styles.ghostCard}>
            <CircleButton icon={faPlus} size="md" color="default" />
            <b>New List</b>
          </GhostCard>
          {data.map(({ _id, ...rest }) => (
            <ListItem key={_id} id={_id} openListItemModal={showModal} getFlag={getFlag} {...rest} />
          ))}
        </div>
      </InfiniteScrollComponent>
      <Modal visible={isVisible} close={hideModal} modalStyle={{ content: { width: '80%', maxHeight: '90%' } }}>
        <ListItemInfo modalProps={modalProps} getFlag={getFlag} showAlert={showAlert} hideAlert={hideAlert} />
      </Modal>
    </>
  );
};

export default List;
