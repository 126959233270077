import gql from 'graphql-tag';
import { useCallback } from 'react';
import { useMutation } from 'react-apollo';

const DELETE_BUSINESS_FROM_LIST = gql`
  mutation removeBusinessFromList($id: ID!) {
    removeBusinessFromList(id: $id) {
      listId
      businessId
    }
  }
`;

const useRemoveBusinessFromList = () => {
  const [removeBusinessFromList] = useMutation(DELETE_BUSINESS_FROM_LIST, {
    refetchQueries: ['lists', 'userList'],
  });

  const handleRemove = useCallback(
    ({ id }) => {
      removeBusinessFromList({ variables: { id } });
    },
    [removeBusinessFromList],
  );

  return handleRemove;
};

export default useRemoveBusinessFromList;
