import React from 'react';
import * as yup from 'yup';
import { Formik, Field, Form } from 'formik';
import Button from 'components/common/Button';
import FormFeedback from 'components/form/FormFeedback';
import InputLabel from 'components/form/Label/Label';
import { countries } from 'countries-list';
import FormSelect from 'components/form/formik/Select';
import { map } from 'lodash';
import TextInput from 'components/form/formik/TextInput';
import TimezoneSelect from './TimezoneSelect';
import styles from './PersonalForm.module.scss';
import AvatarField from './avatar/AvatarField';

const countriesList = map(countries, (object, key) => ({
  label: object.name,
  value: key,
}));

const personalShape = {
  fullName: yup.string().required('Full name is required.'),
  country: yup.string().nullable(),
  timeZone: yup.string().nullable(),
};

const schema = yup.object().shape(personalShape);

const PersonalForm = ({ onSubmit, initialValues, response }) => {
  return (
    <>
      <div>
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
          {({ isSubmitting, errors }) => (
            <div>
              <div>
                <Form className={styles.form}>
                  <Field name="avatar" component={AvatarField} />
                  <Field
                    type="text"
                    name="fullName"
                    component={TextInput}
                    placeholder="Enter your full name"
                    label="Full name"
                  />
                  <InputLabel>Country</InputLabel>
                  <Field
                    name="country"
                    component={FormSelect}
                    options={countriesList}
                    placeholder="Choose your country"
                  />
                  <InputLabel>Time zone</InputLabel>
                  <Field name="timeZone" component={TimezoneSelect} placeholder="Choose time zone" />
                  <FormFeedback valid={false} style={{ display: 'block' }}>
                    {errors.form}
                  </FormFeedback>
                  <div className={styles.form__button}>
                    <Button disabled={isSubmitting} loading={isSubmitting} color="secondary" type="submit">
                      Save changes
                    </Button>
                  </div>
                  {response && (
                    <div className={response.isError ? styles.wrongResponse : styles.successResponse}>
                      {response.message}
                    </div>
                  )}
                </Form>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </>
  );
};

export default PersonalForm;
