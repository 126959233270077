import React from 'react';
import moment from 'moment';
import get from 'lodash/get';
import Table from 'components/tables/table';
import Button from 'components/common/Button';
import CircleButton from 'components/common/Circle-Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import styles from './ListItemInfo.module.scss';

const renderReportButton = ({ row, showModal }) => {
  return (
    <Button
      className={styles.reportButton}
      onClick={() => {
        showModal({ type: 'contact', data: row.original });
      }}
    >
      Report
    </Button>
  );
};

const renderDeleteButton = ({ row, removeBusinessContactFromList, showAlert, hideAlert }) => {
  const { _id: contactId, email } = get(row, 'original', {});
  const handleClick = () => {
    showAlert({
      title: `Are you sure you want to delete this: ${email} contact?`,
      actions: [
        {
          title: 'Delete',
          onClick: () => {
            removeBusinessContactFromList({ contactId });
            hideAlert();
          },
        },
      ],
    });
  };

  return (
    <CircleButton
      className={styles.deleteButton}
      customIcon={<img src={require('assets/icons/trash.svg')} />}
      onClick={handleClick}
    />
  );
};

const BusinessContactsSubTable = (props) => {
  const { contacts, removeBusinessContactFromList, showAlert, hideAlert, showModal } = props;

  const columns = React.useMemo(
    () => [
      {
        Header: 'Full name',
        accessor: 'fullName',
      },
      {
        Header: 'Role',
        Cell: () => 'CEO',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Social Accounts',
        Cell: () => (
          <a key="linked-in" href="https://www.linkedin.com/" target="_blank">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        ),
      },
      {
        Header: 'Last email sent',
        Cell: () => moment().format('MMMM DD, YYYY'),
      },
      {
        Header: 'Template name',
        Cell: () => 'Template 1',
      },
      {
        Header: 'Response rate',
        Cell: () => '55%',
      },
      {
        Header: '',
        id: 'report-button',
        Cell: (data) => renderReportButton({ ...data, showModal }),
      },
      {
        Header: '',
        id: 'delete-button',
        Cell: (data) => renderDeleteButton({ ...data, removeBusinessContactFromList, showAlert, hideAlert }),
      },
    ],
    [showModal, removeBusinessContactFromList, showAlert, hideAlert],
  );

  // console.log('contacts', contacts);

  return (
    <Table
      className={styles.businessContactsTable}
      columns={columns}
      data={contacts}
      getRowProps={(row) => {
        const {
          original: { _id },
        } = row;
        return {
          key: _id,
        };
      }}
    />
  );
};

export default BusinessContactsSubTable;
