import gql from 'graphql-tag';
import { useCallback } from 'react';
import { useMutation } from 'react-apollo';
import { useAlert } from 'components/common/Alert';
import routePaths from 'router/route-paths';
import { toast } from 'react-toastify';
import get from 'lodash/get';

export const CONFIRM_EMAIL_MUTATION = gql`
  mutation confirmEmail($token: String!) {
    confirmEmail(token: $token)
  }
`;

const useConfirmEmail = (history) => {
  const { showAlert } = useAlert();

  const [confirmEmail] = useMutation(CONFIRM_EMAIL_MUTATION, {
    refetchQueries: ['me'],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      const { confirmEmail: tokenValid } = data;
      if (tokenValid) {
        toast.success('Email confirmed');
        history.push(routePaths.login);
      }
    },
  });

  const handleConfirmEmail = useCallback(
    async (token) => {
      try {
        const res = await confirmEmail({ variables: { token } });
        return res;
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        showAlert({ title: error.graphQLErrors[0].message });
        e.raw = error;
        throw e;
      }
    },
    [confirmEmail, showAlert],
  );

  return handleConfirmEmail;
};

export default useConfirmEmail;
