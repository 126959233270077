import React from 'react';
import useUpdateProfileHandler from 'components/profile/useUpdateProfileHandler';
import useUser from 'hooks/useUser';
import PersonalForm from './PersonalForm';

const PersonalTab = () => {
  const [me] = useUser();
  const { fullName, country, timeZone, avatar } = me;
  const initialValues = { fullName, country, timeZone, avatar };
  const { handleSubmit, response } = useUpdateProfileHandler();

  return <PersonalForm onSubmit={handleSubmit} initialValues={initialValues} response={response} />;
};

export default PersonalTab;
