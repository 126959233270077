/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import TokenManager from 'modules/token-manager';
import { ME_QUERY } from 'hooks/useUser';
import first from 'lodash/first';

const useAuth = (mutation, params = {}) => {
  const [authMutation, { loading }] = useMutation(mutation, params);
  const client = useApolloClient();
  const auth = React.useCallback(
    async (variables) => {
      try {
        const res = await authMutation({ variables });
        const gqlResultKey = Object.keys(res.data)[0];
        const _token = res.data[gqlResultKey].token;
        TokenManager.setToken(_token);
        const resultData = first(Object.values(res.data));

        client.writeQuery({
          query: ME_QUERY,
          data: { me: resultData.user },
        });
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error;
        throw e;
      }
    },
    [authMutation, client],
  );

  return [auth, loading];
};

export default useAuth;
