import React from 'react';
import DonutChart from 'components/statistics/DonutChart';
import CurveChart from 'components/statistics/CurveChart';
import Card from 'components/common/Card';
import AppPage from 'components/layout/AppPage';
import Colors from 'utils/chartColors';
import Label from 'components/statistics/label';
import TabTitle from 'components/common/TabTitle';
import BarChart from 'components/statistics/BarChart';
import { donutChartData, barChartData, barChartKeys, linesData } from './data';
import styles from './Statistics.module.scss';

const lineLabels = ['Text example', 'Text example'];

const Statistics = () => {
  return (
    <AppPage>
      <div>
        <TabTitle>General all users Statistics</TabTitle>
        <div className={styles.container}>
          <div className={styles.message}>
            Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem
            aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo.
            Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit.{' '}
          </div>
          <div className={styles.cards}>
            <Card>
              <div className={styles.chartWrapper}>
                <BarChart data={barChartData} keys={barChartKeys} />
                <div className={styles.barChartLabels}>
                  {barChartKeys.map((item, index) => (
                    <Label name={item} color={Colors[index]} />
                  ))}
                </div>
              </div>
            </Card>
            <Card>
              <DonutChart data={donutChartData} />
              <div>
                {donutChartData.map((item, index) => (
                  <Label name={item.label} color={Colors[index]} percent={12} />
                ))}
              </div>
            </Card>
            <Card>
              <CurveChart data={linesData} labels={lineLabels} />
            </Card>
          </div>
        </div>
      </div>
    </AppPage>
  );
};

export default Statistics;
