import React from 'react';
import { ReactComponent as PlanImg1 } from 'assets/img/price/price-1.svg';
import { ReactComponent as PlanImg2 } from 'assets/img/price/price-2.svg';
import { ReactComponent as PlanImg3 } from 'assets/img/price/price-3.svg';
import { ReactComponent as PlanImg4 } from 'assets/img/price/price-4.svg';

const plans = {
  free: {
    businessesPerMonth: 30,
    requests: 2,
    dataAndLists: 'Free',
    updates: true,
    withEmailAddresses: true,
    emailValidation: true,
    socialMedia: true,
    checkedBusinesses: true,
    tutorials: true,
    searchesAndResults: -1,
    emailPerBusiness: 'All',
    customListRequest: false,
    exportCSV: false,
    unusedBusinesses: false,
    lifeTimeAccess: false,
    emailCampaigns: 'Free',
    connectToGmail: true,
    addYourOwnTemplate: true,
    emailCampaignPerformance: true,
    previewEmails: true,
    monthlyEmailCampaigns: 1,
    ourSuggestedTemplates: false,
    interactionWithRates: false,
    downloadReport: false,
    removeSentWithBoost: false,
    statistics: 'Free',
    overalStatistics: false,
    price: 0,
    yearlyPrice: Math.ceil(0 * 0.7),
  },
  starter: {
    businessesPerMonth: 150,
    requests: 5,
    dataAndLists: 'Starter',
    updates: true,
    withEmailAddresses: true,
    emailValidation: true,
    socialMedia: true,
    checkedBusinesses: true,
    tutorials: true,
    searchesAndResults: -1,
    emailPerBusiness: 'All',
    customListRequest: true,
    exportCSV: true,
    unusedBusinesses: true,
    lifeTimeAccess: true,
    emailCampaigns: 'Starter',
    connectToGmail: true,
    addYourOwnTemplate: true,
    emailCampaignPerformance: true,
    previewEmails: true,
    monthlyEmailCampaigns: 3,
    ourSuggestedTemplates: true,
    interactionWithRates: false,
    downloadReport: false,
    removeSentWithBoost: false,
    statistics: 'Starter',
    overalStatistics: false,
    price: 49,
    yearlyPrice: Math.ceil(49 * 0.7),
  },
  pro: {
    businessesPerMonth: 1000,
    requests: 30,
    dataAndLists: 'Pro',
    updates: true,
    withEmailAddresses: true,
    emailValidation: true,
    socialMedia: true,
    checkedBusinesses: true,
    tutorials: true,
    searchesAndResults: -1,
    emailPerBusiness: 'All',
    customListRequest: true,
    exportCSV: true,
    unusedBusinesses: true,
    lifeTimeAccess: true,
    emailCampaigns: 'Pro',
    connectToGmail: true,
    addYourOwnTemplate: true,
    emailCampaignPerformance: true,
    previewEmails: true,
    monthlyEmailCampaigns: -1,
    ourSuggestedTemplates: true,
    interactionWithRates: true,
    downloadReport: true,
    statistics: 'Pro',
    removeSentWithBoost: true,
    overalStatistics: true,
    price: 149,
    yearlyPrice: Math.ceil(149 * 0.7),
  },
  enterprise: {
    businessesPerMonth: 3000,
    requests: 100,
    dataAndLists: 'Enterprise',
    updates: true,
    withEmailAddresses: true,
    emailValidation: true,
    socialMedia: true,
    checkedBusinesses: true,
    tutorials: true,
    searchesAndResults: -1,
    emailPerBusiness: 'All',
    customListRequest: true,
    exportCSV: true,
    unusedBusinesses: true,
    lifeTimeAccess: true,
    emailCampaigns: 'Enterprise',
    connectToGmail: true,
    addYourOwnTemplate: true,
    emailCampaignPerformance: true,
    previewEmails: true,
    monthlyEmailCampaigns: -1,
    ourSuggestedTemplates: true,
    interactionWithRates: true,
    downloadReport: true,
    removeSentWithBoost: true,
    statistics: 'Enterprise',
    overalStatistics: true,
    price: 299,
    yearlyPrice: Math.ceil(299 * 0.7),
  },
};
const PlanHeadings = [
  {
    name: 'free',
    title: 'Free',
    logo: <PlanImg1 />,
  },
  {
    name: 'starter',
    title: 'Starter',
    logo: <PlanImg2 />,
  },
  {
    name: 'pro',
    title: 'Pro',
    logo: <PlanImg3 />,
    highlight: true,
  },
  {
    name: 'enterprise',
    title: 'Enterprise',
    logo: <PlanImg4 />,
  },
];
const PlanRows = [
  {
    id: 2,
    label: 'Number of businesses per month',
    tip:
      'Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa.',
    isRowGroup: false,
    name: 'businessesPerMonth',
  },
  {
    id: 3,
    label: 'Number of Requests',
    tip: 'Totam rem aperiam eaque ipsa.',
    isRowGroup: false,
    name: 'requests',
  },
  {
    id: 4,
    label: 'Data and lists',
    tip: '',
    isRowGroup: true,
    name: 'dataAndLists',
    items: [
      {
        id: 5,
        label: 'Regular data updates',
        tip: '',
        isRowGroup: false,
        name: 'updates',
      },
      {
        id: 6,
        label: 'Every business with email addresses',
        tip: '',
        isRowGroup: false,
        name: 'withEmailAddresses',
      },
      {
        id: 7,
        label: 'Email addresses validation (unbounce)',
        tip: '',
        isRowGroup: false,
        name: 'emailValidation',
      },
      {
        id: 8,
        label: 'Businesses social media Facebook, Instagram, Linkedin, Twitter, pinterest',
        tip: '',
        isRowGroup: false,
        name: 'socialMedia',
      },
      {
        id: 9,
        label: 'Manually checked businesses',
        tip:
          'Unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa.',
        isRowGroup: false,
        name: 'checkedBusinesses',
      },
      {
        id: 10,
        label: 'Training tutorials',
        tip: '',
        isRowGroup: false,
        name: 'tutorials',
      },
      {
        id: 11,
        label: 'Searches and results',
        tip: 'Accusantium doloremque laudantium, totam rem aperiam eaque ipsa.',
        isRowGroup: false,
        name: 'searchesAndResults',
      },
      {
        id: 12,
        label: 'Email addresses per business',
        tip: '',
        isRowGroup: false,
        name: 'emailPerBusiness',
      },
      {
        id: 13,
        label: 'Custom list request',
        tip: '',
        isRowGroup: false,
        name: 'customListRequest',
      },
      {
        id: 14,
        label: 'Export CSV',
        tip:
          'Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa.',
        isRowGroup: false,
        name: 'exportCSV',
      },
      {
        id: 15,
        label: 'Unused Number of businesses Rollover',
        tip: '',
        isRowGroup: false,
        name: 'unusedBusinesses',
      },
      {
        id: 16,
        label: 'Life time access',
        tip:
          'Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa.',
        isRowGroup: false,
        name: 'lifeTimeAccess',
      },
    ],
  },
  {
    id: 17,
    label: 'Email campaigns (Human-like sending)',
    tip: '',
    isRowGroup: true,
    name: 'emailCampaigns',
    items: [
      {
        id: 18,
        label: 'Connect to Gmail / Outlook email account',
        tip: '',
        isRowGroup: false,
        name: 'connectToGmail',
      },
      {
        id: 19,
        label: 'Add your own template',
        tip: '',
        isRowGroup: false,
        name: 'addYourOwnTemplate',
      },
      {
        id: 20,
        label: 'Email campaign performance',
        tip: '',
        isRowGroup: false,
        name: 'emailCampaignPerformance',
      },
      {
        id: 21,
        label: 'Preview all your emails before sending',
        tip: '',
        isRowGroup: false,
        name: 'previewEmails',
      },
      {
        id: 22,
        label: 'Monthly email campaigns',
        tip: '',
        isRowGroup: false,
        name: 'monthlyEmailCampaigns',
      },
      {
        id: 23,
        label: 'Our suggested templates',
        tip: '',
        isRowGroup: false,
        name: 'ourSuggestedTemplates',
      },
      {
        id: 24,
        label: 'Sent, Viewed, Replied, bounced, clicked rates',
        tip: '',
        isRowGroup: false,
        name: 'interactionWithRates',
      },
      {
        id: 25,
        label: 'Download email campaigns report',
        tip: '',
        isRowGroup: false,
        name: 'downloadReport',
      },
      {
        id: 26,
        label: 'Remove sent with "Boost"',
        tip: '',
        isRowGroup: false,
        name: 'removeSentWithBoost',
      },
    ],
  },
  {
    id: 27,
    label: 'Statistics',
    tip:
      'Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa.',
    isRowGroup: true,
    name: 'statistics',
    items: [
      {
        id: 28,
        label:
          'Overall system statistics: Get statistics of all system users by cities, categories, templates, time frames, and businesses.',
        tip: '',
        isRowGroup: false,
        name: 'overalStatistics',
      },
    ],
  },
];
export { PlanHeadings, PlanRows, plans };
