import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppPage from 'components/layout/AppPage';
import useConfirmEmail from 'hooks/auth/useConfirmEmail';

const VerifyEmail = () => {
  const history = useHistory();
  const { token } = useParams();

  const handleConfirmEmail = useConfirmEmail(history);

  useEffect(() => {
    handleConfirmEmail(token);
  }, [handleConfirmEmail, history, token]);

  return <AppPage />;
};

export default VerifyEmail;
