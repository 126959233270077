import React from 'react';
import settings from 'settings';
import ReCAPTCHA from 'react-google-recaptcha';
import wrapFormGroup from './wrapFormGroup';

const Recaptcha = React.forwardRef((props, ref) => (
  <ReCAPTCHA ref={ref} sitekey={settings.recaptchaSiteKey} onChange={props.onChange} className={props.className} />
));

export default wrapFormGroup(Recaptcha);
