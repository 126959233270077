import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Title = ({ title, icon }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <FontAwesomeIcon icon={icon} />
      <div style={{ marginLeft: '5px' }}>{title}</div>
    </div>
  );
};

const tabs = [
  {
    id: 1,
    title: <Title icon={faEnvelope} title="Email" />,
    type: 'email',
  },
  {
    id: 2,
    title: <Title icon={faFacebookF} title="Facebook" />,
    type: 'facebook',
  },
  {
    id: 3,
    title: <Title icon={faLinkedinIn} title="Linkedin" />,
    type: 'linked_in',
  },
  {
    id: 4,
    title: <Title icon={faInstagram} title="Instagram" />,
    type: 'instagram',
  },
];

export default tabs;
