/* eslint-disable react/no-children-prop */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AppPage from 'components/layout/AppPage';
import useUser from 'hooks/useUser';
import ActivateAccount from 'pages/ActivateAccount';
import VerifyEmail from 'pages/VerifyEmail';
import AppLayout from '../components/layouts/AppLayout';
import NotFound from '../pages/NotFound';
import routes from './routes';
import routePaths from './route-paths';

const appRoutes = routes.filter(({ group }) => group === 'app');

const withLayout = (C) => (props) => (
  <AppLayout {...props}>
    <C {...props} />
  </AppLayout>
);
const withPage = (C) => (props) => (
  <AppPage {...props}>
    <C {...props} />
  </AppPage>
);
const EnhancedNotFound = withLayout(withPage(NotFound));

const AppRouter = () => {
  const [user] = useUser();
  return (
    <>
      <Route path={routePaths.activateAccount} exact>
        <ActivateAccount />
      </Route>
      <Switch>
        <Route path={routePaths.verifyEmail} exact>
          <VerifyEmail />
        </Route>
        {!user.emailVerified && <Redirect to={routePaths.activateAccount} />}
        <Redirect from={routePaths.home} to={routePaths.default} exact />
        {appRoutes.map(({ path, component }) => (
          <Route path={path} exact key={path}>
            {withLayout(component)}
          </Route>
        ))}
        <Redirect from={routes.home} to={routePaths.default} exact />
        <Redirect from={routes.login} to={routePaths.default} exact />
        <Redirect from={routes.signUp} to={routePaths.default} exact />
        <Route component={EnhancedNotFound} />
      </Switch>
    </>
  );
};

export default AppRouter;
