/* globals FB */
import { useEffect, useCallback } from 'react';
import gql from 'graphql-tag';
import useAuth from 'hooks/auth/useAuth';
import useUser, { CURRENT_USER_FRAGMENT } from 'hooks/useUser';
import { useHistory } from 'react-router-dom';
import routePaths from 'router/route-paths';
import { toast } from 'react-toastify';

const AUTH_WITH_FACEBOOK = gql`
  mutation authWithFacebook($token: String!, $id: String!) {
    authWithFacebook(token: $token, id: $id) {
      token
      user {
        ...currentUser
      }
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

const ID = 'facebook-jssdk';
const TAG = 'script';

const useFacebookAuth = () => {
  const [me] = useUser();
  const mutationParams = me ? { refetchQueries: ['me'] } : {};

  const [auth, loading] = useAuth(AUTH_WITH_FACEBOOK, mutationParams);
  const history = useHistory();

  useEffect(() => {
    window.fbAsyncInit = () => {
      FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v6.0',
      });

      FB.AppEvents.logPageView();
    };

    const fjs = document.getElementsByTagName(TAG)[0];
    if (document.getElementById(ID)) return;
    const js = document.createElement(TAG);
    js.id = ID;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  }, []);

  const login = useCallback(() => {
    FB.login(
      ({ authResponse }) => {
        if (!authResponse) {
          return;
        }
        const { status, accessToken, userID } = authResponse;

        if (status && status !== 'connected') {
          toast.error('Login failed');
          return;
        }
        auth({ token: accessToken, id: userID })
          .then(() => {
            if (me) toast.success('Service was successfully connected');
            else history.push(routePaths.home);
          })
          .catch((e) => toast.error(e.message));
      },
      { scope: 'public_profile,email' },
    );
  }, [auth, history, me]);

  return [login, loading];
};

export default useFacebookAuth;
