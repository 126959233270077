import React from 'react';
import pluralize from 'pluralize';
import Rating from 'react-rating';
import get from 'lodash/get';
import Table from 'components/tables/table';
import Button from 'components/common/Button';
import CircleButton from 'components/common/Circle-Button';
import BusinessContactsSubTable from 'components/list/BusinessContactsSubTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import Modal, { useModal } from 'components/common/Modal';
import {
  faTwitter,
  faFacebookSquare,
  faLinkedin,
  faInstagramSquare,
  faPinterestSquare,
} from '@fortawesome/free-brands-svg-icons';
import styles from './ListItemInfo.module.scss';
import Report from './Report/Report';

const getSocialIcon = (socialName) => {
  switch (socialName) {
    case 'facebook':
      return faFacebookSquare;
    case 'instagram':
      return faInstagramSquare;
    case 'linked_in':
      return faLinkedin;
    case 'twitter':
      return faTwitter;
    case 'pinterest':
      return faPinterestSquare;
    default:
      return '';
  }
};

const renderSocials = ({ cell }) => {
  const { value } = cell;
  const icons = value.map(({ type, link }) => {
    const socialIcon = getSocialIcon(type);
    return (
      <a key={`${type}-${link}`} href={link} target="_blank">
        <FontAwesomeIcon icon={socialIcon} />
      </a>
    );
  });
  return icons;
};

const renderRating = ({ row }) => {
  const {
    original: { rating, reviewCount },
  } = row;
  return (
    <span>
      <Rating
        readonly
        fractions={2}
        initialRating={rating}
        emptySymbol={<img src={require('assets/icons/empty-star.svg')} />}
        fullSymbol={<img src={require('assets/icons/full-star.svg')} />}
      />
      <div>
        {reviewCount} {pluralize('Review', reviewCount)}
      </div>
    </span>
  );
};

const renderReportButton = ({ row, showModal }) => {
  return (
    <Button className={styles.reportButton} onClick={() => showModal({ type: 'businessUnit', data: row.original })}>
      Report
    </Button>
  );
};

const renderDeleteButton = ({ row, removeBusinessFromList, showAlert, hideAlert }) => {
  const { _id: id, name } = get(row, 'original', {});
  const handleClick = () => {
    showAlert({
      title: `Are you sure you want to delete this: ${name} business?`,
      actions: [
        {
          title: 'Delete',
          onClick: () => {
            removeBusinessFromList({ id });
            hideAlert();
          },
        },
      ],
    });
  };

  return (
    <CircleButton
      className={styles.deleteButton}
      customIcon={<img src={require('assets/icons/trash.svg')} />}
      useSortBy
      onClick={handleClick}
    />
  );
};

const BusinessTable = (props) => {
  const { businessUnits, removeBusinessFromList, removeBusinessContactFromList, showAlert, hideAlert } = props;
  const { showModal, modalProps, hideModal, isVisible } = useModal();

  const columns = React.useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: 'expander', // It needs an ID
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span {...row.getToggleRowExpandedProps()}>
            <FontAwesomeIcon icon={row.isExpanded ? faCaretDown : faCaretRight} />
          </span>
        ),
      },
      {
        Header: 'Website',
        accessor: 'urls.webSiteUrl',
        isSortable: true,
      },
      {
        Header: 'Business name',
        accessor: 'name',
        isSortable: true,
      },
      {
        Header: 'City',
        accessor: 'location.city',
        isSortable: true,
      },
      {
        Header: 'Full Address',
        accessor: 'location.prettyLocation',
        isSortable: false,
      },
      {
        Header: 'Zip',
        accessor: 'location.zipCode',
        isSortable: false,
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumbers.local',
        isSortable: false,
      },
      {
        Header: 'Social Accounts',
        accessor: 'social',
        isSortable: false,
        Cell: renderSocials,
      },
      {
        Header: '',
        accessor: 'rating',
        isSortable: false,
        Cell: renderRating,
      },
      {
        Header: '',
        id: 'report-button',
        Cell: (data) => renderReportButton({ ...data, showModal }),
      },
      {
        Header: '',
        id: 'delete-button',
        Cell: (data) =>
          renderDeleteButton({ ...data, removeBusinessFromList, removeBusinessContactFromList, showAlert, hideAlert }),
      },
    ],
    [removeBusinessFromList, removeBusinessContactFromList, hideAlert, showAlert, showModal],
  );

  // Create a function that will render our row sub components
  const renderRowSubComponent = ({ row }) => (
    <BusinessContactsSubTable
      contacts={row.original.contacts}
      removeBusinessContactFromList={removeBusinessContactFromList}
      showAlert={showAlert}
      hideAlert={hideAlert}
      showModal={showModal}
    />
  );

  const getRowProps = React.useCallback((row) => {
    const {
      original: { _id },
    } = row;
    return {
      key: _id,
    };
  }, []);

  return (
    <>
      <Table
        isExpandable
        autoResetExpanded={false}
        className={styles.listItemTable}
        columns={columns}
        data={businessUnits}
        getRowProps={getRowProps}
        renderRowSubComponent={renderRowSubComponent}
      />
      <Modal visible={isVisible} close={hideModal}>
        <Report {...modalProps} onCancel={hideModal} />
      </Modal>
    </>
  );
};

export default BusinessTable;
