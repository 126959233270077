import gql from 'graphql-tag';
import { CURRENT_USER_FRAGMENT } from 'hooks/useUser';
import useAuth from './useAuth';

export const SIGN_UP_MUTATION = gql`
  mutation signUp($email: String!, $password: String!, $fullName: String!, $consent: Boolean!) {
    createAccount(email: $email, password: $password, fullName: $fullName, consent: $consent) {
      user {
        ...currentUser
      }
      token
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

const useSignUp = () => {
  const [signUp] = useAuth(SIGN_UP_MUTATION);
  return signUp;
};

export default useSignUp;
