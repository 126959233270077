import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

export const CHANGE_PASSWORD = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

const useChangePassword = () => {
  const [changePasswordMutation] = useMutation(CHANGE_PASSWORD);

  const changePassword = useCallback(
    async (variables) => {
      try {
        await changePasswordMutation({
          variables: { ...variables },
        });
        return;
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error;
        throw e;
      }
    },
    [changePasswordMutation],
  );

  return changePassword;
};

export default useChangePassword;
