import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'components/common/Alert';
import useUserSubscription from 'hooks/useUserSubscription';

const useUpgradeReminder = () => {
  const { showAlert } = useAlert();
  const subscription = useUserSubscription();
  const history = useHistory();
  const goToSubscribePage = useCallback(() => history.push('/subscribe'), [history]);

  const checkCounts = useCallback(
    (countsToCheck) => {
      let spentResource = null;
      const enoughCounts = countsToCheck.reduce((acc, countName) => {
        if (!acc) return acc;
        if (subscription[`${countName}Left`] <= 0) {
          spentResource = countName;
          return false;
        }
        return acc;
      }, true);
      if (!enoughCounts) {
        showAlert({
          title: `You have no ${spentResource} left`,
          image: require('assets/img/upgrade-plan.svg'),
          actions: [
            {
              title: 'Upgrade',
              color: 'secondary',
              onClick: goToSubscribePage,
            },
          ],
        });
        return false;
      }
      return true;
    },
    [goToSubscribePage, showAlert, subscription],
  );

  return checkCounts;
};

export default useUpgradeReminder;
