import React from 'react';
import classnames from 'classnames';
import wrapFormGroup from '../wrapFormGroup';
import styles from './TextInput.module.scss';

export const TextInput = ({ name, onChange, className, ...props }) => {
  const handleChange = React.useCallback(
    (e) => {
      onChange(e.target.value);
    },
    [onChange],
  );
  return <input {...props} className={classnames(styles.input, className)} onChange={handleChange} />;
};

export default wrapFormGroup(TextInput);
