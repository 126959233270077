import { faGoogle, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import theme from 'styles/theme';

const GOOGLE_BUTTON_ID = 'google-oauth-btn';
const FACEBOOK_BUTTON_ID = 'facebook-btn';
const LINKED_IN_BUTTON_ID = 'linked_in-btn';

export default [
  {
    id: GOOGLE_BUTTON_ID,
    name: 'google',
    icon: faGoogle,
    iconColor: theme.googleIconColor,
  },
  {
    id: FACEBOOK_BUTTON_ID,
    name: 'facebook',
    icon: faFacebookF,
    iconColor: theme.facebookIconColor,
  },
  {
    id: LINKED_IN_BUTTON_ID,
    name: 'linked_in',
    icon: faLinkedinIn,
    iconColor: theme.linkedInIconColor,
  },
];
