import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { AlertProvider } from 'components/common/Alert';
import RootRouter from './router/RootRouter';
import ApolloClient from './modules/apollo';

import './styles/index.scss';

const App = () => (
  <ApolloProvider client={ApolloClient}>
    <AlertProvider>
      <Router>
        <RootRouter />
      </Router>
      <ToastContainer />
    </AlertProvider>
  </ApolloProvider>
);

export default App;
