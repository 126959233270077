import React from 'react';
import AppPage from 'components/layout/AppPage';
import EmailConnectForm from 'components/campaign/emailConnectForm';

const EmailConnect = () => {
  return (
    <AppPage>
      <EmailConnectForm />
    </AppPage>
  );
};

export default EmailConnect;
