import React from 'react';
import { LineChart, Line, CartesianGrid, Tooltip } from 'recharts';
import Colors from 'utils/chartColors';
import styles from './CurveChart.module.scss';
import Label from './label';

const keyExtractor = (data) => {
  if (!data) return [];
  const keys = data[0];
  delete keys.name;
  return Object.keys(keys);
};

const CurveChart = ({ data, labels }) => {
  if (!data) return null;

  const keys = keyExtractor(data);

  return (
    <div>
      <LineChart width={300} height={100} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip contentStyle={{ width: '75px', height: '75px', fontSize: '11px' }} />
        {keys.map((key, index) => (
          <Line type="monotone" dataKey={key} stroke={Colors[index]} strokeWidth={2} vertical="false" />
        ))}
      </LineChart>
      <div className={styles.labels}>
        {labels.map((label, index) => (
          <div className={styles.label}>
            <Label name={label} color={Colors[index]} percent={12} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CurveChart;
