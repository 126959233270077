import React from 'react';
import pluralize from 'pluralize';
import useListItemInfo from 'hooks/useListItemInfo';
import useRemoveBusinessFromList from 'components/list/useRemoveBusinessFromList';
import useRemoveBusinessContactFromList from 'components/list/useRemoveBusinessContactFromList';
import BusinessTable from 'components/list/BusinessTable';
import Loading from 'components/common/Loading';
import SearchBar from 'components/list/SearchBar';
import styles from './ListItemInfo.module.scss';

const GeneralInfo = (props) => {
  const { location, listCategory, businessesCount, getFlag } = props;
  const { country, city } = location;
  return (
    <div className={styles.generalInfo}>
      <div className={styles.item}>
        <div className="flag">{getFlag(country)}</div>
        <div className="country">{country}</div>
      </div>
      <div className={styles.item}>{city}</div>
      <div className={styles.item}>{listCategory}</div>
      <div className={styles.item}>
        {businessesCount} {pluralize('Business', businessesCount)}
      </div>
    </div>
  );
};

const ListItemInfo = (props) => {
  const {
    modalProps: { listId },
    getFlag,
    showAlert,
    hideAlert,
  } = props;

  const removeBusinessContactFromList = useRemoveBusinessContactFromList();
  const removeBusinessFromList = useRemoveBusinessFromList();
  const { list, loading, handleChange, handleCSVExport, handleXlsxExport } = useListItemInfo(listId);

  if (loading && listId) return <Loading />;
  if (!list) return null;
  const { businessUnits, location, category: listCategory } = list;

  // console.log('businessUnits', businessUnits);
  return (
    <>
      <div className={styles.listItemInfo}>
        <GeneralInfo
          location={location}
          listCategory={listCategory}
          businessesCount={businessUnits.length}
          getFlag={getFlag}
        />
        <SearchBar
          businessUnits={businessUnits}
          handleChange={handleChange}
          handleCSVExport={handleCSVExport}
          handleXlsxExport={handleXlsxExport}
        />
        <BusinessTable
          businessUnits={businessUnits}
          removeBusinessFromList={removeBusinessFromList}
          removeBusinessContactFromList={removeBusinessContactFromList}
          showAlert={showAlert}
          hideAlert={hideAlert}
        />
      </div>
    </>
  );
};

export default ListItemInfo;
