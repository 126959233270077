import { useMemo } from 'react';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';

export const TEMPLATE_FRAGMENT = gql`
  fragment template on Template {
    _id
    name
    subject
    message
    type
    attachments {
      url
      name
      size
    }
  }
`;

export const GET_TEMPLATES_QUERY = gql`
  query templates($type: TemplateType!, $offset: Int!, $limit: Int!) {
    templates(type: $type, offset: $offset, limit: $limit) @connection(key: "templates", filter: ["type"]) {
      ...template
    }
  }
  ${TEMPLATE_FRAGMENT}
`;

const useTemplates = (type) => {
  const { data, loading, fetchMore } = useQuery(GET_TEMPLATES_QUERY, {
    variables: {
      type,
      limit: 10,
      offset: 0,
    },
  });

  const templates = useMemo(() => get(data, 'templates', []), [data]);

  return {
    templates,
    loading,
    fetchMore,
  };
};

export default useTemplates;
