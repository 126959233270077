import React, { useCallback } from 'react';
import { NavLink, Link } from 'react-router-dom';
import useLogout from 'hooks/auth/useLogout';
import { useAlert } from 'components/common/Alert';
import styles from './Sidebar.module.scss';

const LogoutButton = () => {
  const logout = useLogout();
  const { showAlert, hideAlert } = useAlert();

  const handleLogoutClick = useCallback(() => {
    showAlert({
      title: 'Are you sure you want to sign out?',
      message: 'Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.',
      image: require('assets/img/logout-confirm.svg'),
      actions: [
        {
          title: 'Cancel',
          color: 'cancel',
          onClick: () => hideAlert(),
        },
        {
          title: 'Sign out',
          color: 'secondary',
          onClick: async () => {
            await logout();
            hideAlert();
          },
        },
      ],
    });
  }, [hideAlert, logout, showAlert]);

  return (
    <button type="button" className={styles.logoutButton} onClick={handleLogoutClick}>
      Sign out
    </button>
  );
};

// const useCollapse = () => {
//   const [collapseOpen, setCollapseOpen] = React.useState(false);
//   const toggleCollapse = React.useCallback(
//     () => setCollapseOpen(!collapseOpen),
//     [setCollapseOpen, collapseOpen],
//   );
//   const closeCollapse = React.useCallback(() => setCollapseOpen(false), [
//     setCollapseOpen,
//   ]);
//   return { collapseOpen, toggleCollapse, closeCollapse, setCollapseOpen };
// };

const Sidebar = (props) => {
  // const { collapseOpen, toggleCollapse, closeCollapse } = useCollapse();
  const { routes } = props;
  return (
    <div className={styles.container}>
      <Link to="/" className={styles.logoContainer}>
        <img alt="..." src={require('assets/img/logo.svg')} />
      </Link>
      <ul className={styles.body}>
        {routes.map((prop) => (
          <li key={prop.path}>
            <NavLink to={prop.path} className={styles.navLink} activeClassName={styles.activeNavLink}>
              <div className={styles.navLinkInner}>
                {prop.icon}
                {prop.name}
              </div>
              <div className={styles.topCurve} />
              <div className={styles.bottomCurve} />
            </NavLink>
          </li>
        ))}
      </ul>
      <LogoutButton />
    </div>
  );
};

export default Sidebar;
