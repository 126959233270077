import get from 'lodash/get';
import moment from 'moment';
import { useMemo } from 'react';
import bundles from 'utils/bundles';
import useUser from './useUser';

const planMap = {
  free: 'Free Plan',
  starter: 'Starter Plan',
  pro: 'Pro Plan',
  enterprise: 'Enterprise Plan',
  null: 'No Plan',
};

const noPlanBundle = {
  businesses: 0,
  requests: 0,
  campaigns: 0,
};

const useUserSubscription = () => {
  const [currentUser] = useUser();
  const { product = null, status, endDate, period } = get(currentUser, 'subscription') || {};
  const { businesses = 0, requests = 0, campaigns = 0 } = get(currentUser, 'bundle.counters') || {};
  const bundle = bundles[product] || noPlanBundle;

  const subscription = useMemo(
    () => ({
      period,
      product,
      plan: planMap[product],
      renewalDate: moment(endDate).format('MMM DD, YYYY'),

      businessesLeft: businesses,
      requestsLeft: requests,
      campaignsLeft: campaigns,

      businessesTotal: bundle.businesses,
      requestsTotal: bundle.requests,
      campaignsTotal: bundle.campaigns,

      businessesUsed: bundle.businesses - businesses,
      requestsUsed: bundle.requests - requests,
      campaignsUsed: bundle.campaigns - campaigns,

      status,
      subDoc: currentUser.subscription,
    }),
    [
      bundle.businesses,
      bundle.campaigns,
      bundle.requests,
      businesses,
      campaigns,
      currentUser.subscription,
      endDate,
      product,
      requests,
      status,
      period,
    ],
  );

  return subscription;
};

export default useUserSubscription;
