import React from 'react';
import useUser from 'hooks/useUser';
import Modal from 'components/common/Modal';
import TermsAndPolicyForm from 'components/auth/TermsAndPolicyForm';
// import Footer from 'components/layout/AppFooter';

const AppPage = ({ children }) => {
  const [{ consent }] = useUser();

  return (
    <>
      <Modal visible={!consent}>
        <TermsAndPolicyForm />
      </Modal>
      <main style={{ padding: 20 }}>{children}</main>
      {/* <Footer /> */}
    </>
  );
};

export default AppPage;
