import React from 'react';
import TextInput from 'components/form/formik/TextInput';

const PhoneNumberInput = (props) => {
  const { form, placeholder } = props;
  const { code } = form.values;

  return (
    <TextInput
      disabled={!code}
      style={{ width: '173px', marginLeft: '5px' }}
      placeholder={code ? placeholder : 'Please choose code'}
      {...props}
    />
  );
};

export default PhoneNumberInput;
