import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import classnames from 'classnames';
import tabs from './tabs';
import styles from './CampaignsStats.module.scss';

const CampaignStats = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Tabs selectedIndex={activeTab} selectedTabClassName={styles.isSelected} onSelect={setActiveTab}>
      <TabList className={styles.tabList}>
        {tabs.map(({ title, id }) => (
          <Tab key={id} className={classnames('react-tabs__tab', styles.tabList__item)}>
            {title}
          </Tab>
        ))}
      </TabList>
      {tabs.map(({ id }) => (
        <TabPanel key={id}>
          <div />
        </TabPanel>
      ))}
    </Tabs>
  );
};

export default CampaignStats;
