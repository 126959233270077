export default [
  {
    id: 1,
    value: 'not-relevant-search',
    label: 'Not relevant to the search terms',
  },
  {
    id: 2,
    value: 'some-reason-2',
    label: 'reason 2',
  },
  {
    id: 3,
    value: 'some-reason-3',
    label: 'reason 3',
  },
];
