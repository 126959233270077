const routePaths = {
  home: '/',
  myLists: '/lists',
  templates: '/templates',
  statistics: '/statistics',
  tutorials: '/tutorials',
  profile: '/profile',
  support: '/support',
  login: '/login',
  signUp: '/sign-up',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  verifyEmail: '/verify-email/:token',
  oAuthLogin: '/oauth',
  activateAccount: '/activate-account',
  campaign: '/campaign',
  campaignSetName: '/campaign/set-name',
  campaignPage: '/campaign/connect-account',
};

routePaths.default = routePaths.myLists;

export default routePaths;
