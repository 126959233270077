import { useCallback } from 'react';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { useApolloClient } from 'react-apollo';

const GET_CATEGORIES = gql`
  query categories($search: String) {
    categories(search: $search)
  }
`;

const useSearchCategories = () => {
  const client = useApolloClient();
  const searchCategories = useCallback(
    async (search, callback) => {
      const { data, error } = await client.query({ query: GET_CATEGORIES, variables: { search } });
      const categories = get(data, 'categories', []);
      callback(categories.map((category) => ({ value: category, label: category })));
    },
    [client],
  );
  return searchCategories;
};

export default useSearchCategories;
