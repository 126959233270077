import React from 'react';
import routes from 'router/routes';
import AppNavbar from '../layout/AppNavbar/AppNavbar';
// import AppFooter from '../components/layout/AppFooter';
import Sidebar from '../layout/Sidebar';
import styles from './AppLayout.module.scss';

const appRoutes = routes.filter(({ group }) => group === 'app');
const usePageTitle = (match) =>
  React.useMemo(() => {
    const matchPath = match && match.path;
    if (!matchPath) return '';
    const matchedRoute = appRoutes.find(({ path }) => matchPath === path);
    if (matchedRoute) return matchedRoute.name;
    return '';
  }, [match]);

const sidebarRoutes = appRoutes.filter(({ sidebar }) => !!sidebar);

const AppLayout = (props) => {
  const { match, children } = props;
  const pageTitle = usePageTitle(match);
  return (
    <div className={styles.container}>
      <Sidebar routes={sidebarRoutes} />
      <div className={styles.content}>
        <AppNavbar pageTitle={pageTitle} />
        {children}
      </div>
    </div>
  );
};

export default AppLayout;
