import { useCallback } from 'react';
import { useMutation } from 'react-apollo';
import { toast } from 'react-toastify';
import { GET_MY_LIST_QUERY, LIST_FRAGMENT } from 'hooks/useMyLists';
import gql from 'graphql-tag';

const CREATE_LIST = gql`
  mutation createList($city: String!, $country: String!, $category: String!, $businessesCount: Int!) {
    createList(city: $city, country: $country, category: $category, businessesCount: $businessesCount) {
      ...list
    }
  }
  ${LIST_FRAGMENT}
`;

const useCreateList = (closeForm) => {
  const [createList] = useMutation(CREATE_LIST, {
    onCompleted: () => {
      toast.success('List successfully created!');
      closeForm();
    },
    onError: () => toast.error('Something went wrong! Try again'),
    refetchQueries: ['me'],
    update: (cache, { data: { createList: newList } }) => {
      const { lists } = cache.readQuery({ query: GET_MY_LIST_QUERY });
      cache.writeQuery({ query: GET_MY_LIST_QUERY, data: { lists: [...lists, newList] } });
    },
  });

  const handleCreateList = useCallback(
    ({ city, country, category, businessesCount }) => {
      createList({ variables: { city, country, category, businessesCount } });
    },
    [createList],
  );

  return handleCreateList;
};

export default useCreateList;
