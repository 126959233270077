import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './AppNavbar.module.scss';

const NavbarItem = ({ icon, title, value, children, iconComponent, className }) => {
  return (
    <li className={classnames(styles.mainNavItem, className)}>
      <div className={styles.item}>
        {!icon ? iconComponent : <FontAwesomeIcon icon={icon} />} <b>{value}</b> <span>{title}</span>
      </div>
      {children}
    </li>
  );
};

export default NavbarItem;
