import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import classnames from 'classnames';
import wrapFormGroup from './wrapFormGroup';
import styles from './TextArea.module.scss';

export const TextArea = ({ onChange, className, minRows = 4, ...props }) => {
  const handleChange = React.useCallback(
    (e) => {
      onChange(e.target.value);
    },
    [onChange],
  );
  return (
    <TextareaAutosize
      {...props}
      className={classnames(styles.textArea, className)}
      onChange={handleChange}
      resize="none"
      minRows={minRows}
    />
  );
};

export default wrapFormGroup(TextArea);
