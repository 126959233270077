import React from 'react';

const Dropzone = ({ dropzone: { getRootProps, getInputProps }, className, children }) => {
  return (
    <div {...getRootProps()} className={className}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

export default Dropzone;
