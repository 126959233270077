import React from 'react';
import styles from './TabTitle.module.scss';

const TabTitle = ({ children }) => {
  return (
    <div className={styles.titleContainer}>
      <div className={styles.title}>{children}</div>
    </div>
  );
};

export default TabTitle;
