import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../Loading';
import styles from './CircleButton.module.scss';

export const colorToClassNameMap = {
  primary: styles.primary,
  danger: styles.danger,
  success: styles.success,
  default: styles.default,
  light: styles.light,
  secondary: styles.secondary,
};

export const sizeToClassNameMap = {
  sm: styles.sm,
  md: styles.md,
};

export const colors = Object.keys(colorToClassNameMap);
export const sizes = Object.keys(sizeToClassNameMap);

const CircleButton = ({
  icon,
  onClick,
  className,
  size = 'md',
  color = 'primary',
  loading,
  as: As = 'button',
  iconColor,
  customIcon,
  ...rest
}) => (
  <As
    type="button"
    className={classnames(styles.btn, sizeToClassNameMap[size], colorToClassNameMap[color], className)}
    onClick={onClick}
    {...rest}
  >
    {loading ? <Loading size={20} color="white" /> : customIcon || <FontAwesomeIcon icon={icon} color={iconColor} />}
  </As>
);

export default CircleButton;
