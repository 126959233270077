import { useMemo } from 'react';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { countries } from 'countries-list';
import { useQuery } from 'react-apollo';

const GET_COUNTRIES = gql`
  query countries {
    countries
  }
`;

const useSearchCountries = () => {
  const { data, loading } = useQuery(GET_COUNTRIES);

  const options = useMemo(
    () => get(data, 'countries', []).map((country) => ({ value: country, label: countries[country].name })),
    [data],
  );
  return [options, loading];
};

export default useSearchCountries;
