import React, { useRef, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import useUploadFile from 'hooks/useUploadFile';
import Modal, { useModal } from 'components/common/Modal';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropzone from 'components/common/Dropzone';
import styles from '../PersonalForm.module.scss';
import ImageCropper from './ImageCrop';

const useUploadAvatarHandler = (file) => {
  const uploadAvatar = useUploadFile(file);

  const handleUpload = useCallback(async () => {
    try {
      return uploadAvatar();
    } catch (error) {
      const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
      e.raw = error;
      throw e;
    }
  }, [uploadAvatar]);

  return handleUpload;
};

const AvatarField = ({ field, form }) => {
  const { value } = field;
  const ref = useRef(null);
  const { showModal, modalProps, isVisible, hideModal } = useModal();

  const uploadAvatar = useUploadAvatarHandler(ref);

  const handleUploadAvatar = useCallback(
    async (newImage) => {
      try {
        ref.current = newImage;
        const url = await uploadAvatar();
        form.setFieldValue('avatar', url);
        hideModal();
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error;
        throw e;
      }
    },
    [form, hideModal, uploadAvatar],
  );

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles.pop();
      ref.current = file;
      showModal({ file });
    },
    [showModal],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const dropzone = { getInputProps, getRootProps };

  const modalStyle = {
    content: { width: '60%', minHeight: '60%' },
  };

  return (
    <div className={styles.uploadAvatarContainer}>
      <Dropzone dropzone={dropzone} className={styles.dropzone}>
        {value ? (
          <img src={value} alt="avatar" className={styles.avatar} />
        ) : (
          <div>
            <FontAwesomeIcon icon={faCamera} />
            <div className={styles.upload}>Upload</div>
          </div>
        )}
      </Dropzone>
      <div className={styles.message}>Maximum size - 2MB. JPG or PNG.</div>
      <Modal visible={isVisible} close={hideModal} modalStyle={modalStyle}>
        <ImageCropper imageProps={modalProps} handleUploadAvatar={handleUploadAvatar} />
      </Modal>
    </div>
  );
};

export default AvatarField;
