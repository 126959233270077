/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import classnames from 'classnames';
import styles from './GhostCard.module.scss';

const GhostCard = ({ customStyle, onClick, children }) => {
  return (
    <div onClick={onClick} className={classnames(styles.ghostCard, customStyle)}>
      {children}
    </div>
  );
};

export default GhostCard;
