import { useState, useCallback } from 'react';
import useUpdateProfile from 'hooks/profile/useUpdateProfile';

const useUpdateProfileSubmitHandler = () => {
  const updateProfile = useUpdateProfile();
  const [response, setResponse] = useState({
    isError: false,
    message: '',
  });

  const handleSubmit = useCallback(
    async ({ code, number, ...restVariables }) => {
      try {
        const variables = code && number ? { phone: { code, number }, ...restVariables } : { ...restVariables };
        await updateProfile(variables);
        setResponse({ isError: false, message: 'Changes saved successfully' });
      } catch (error) {
        setResponse({ isError: true, message: error.message });
      }
    },
    [updateProfile],
  );

  return { handleSubmit, response };
};

export default useUpdateProfileSubmitHandler;
